import GenericInformationTable, {
    TableRow
} from "lib/table/GenericInformationTable";
import {
    getContactReasonDisplayName,
    getDepartmentDisplayName,
    getMoveInDateDisplayName
} from "../utils/wohnenImAlterLeadProductCriteriaTable";
import { getCareLevelDisplayName } from "types/careLevel";

interface ClientInformationInfoProps {
    careLevel: string | null;
    moveInDate: string | null;
    contactReason: string | null;
    department: string | null;
}

/**
 * WohnenImAlter LeadClientInfomationTable renders key lead information using the GenericInformationTable component.
 */
export function WohnenImAlterLeadProductCriteriaTable({
    careLevel,
    moveInDate,
    contactReason,
    department
}: Readonly<ClientInformationInfoProps>) {
    const filteredRows = [
        {
            fieldName: "Pflegegrad:",
            value: careLevel ? getCareLevelDisplayName(careLevel) : null
        },
        {
            fieldName: "Einzugstermin:",
            value:
                moveInDate !== null && moveInDate !== undefined
                    ? getMoveInDateDisplayName(moveInDate)
                    : null
        },
        {
            fieldName: "Kontaktgrund:",
            value:
                contactReason != null
                    ? getContactReasonDisplayName(contactReason)
                    : null
        },
        {
            fieldName: "Fachbereich:",
            value:
                department != null ? getDepartmentDisplayName(department) : null
        }
    ].filter((row) => row.value !== null && row.value !== undefined);

    const rows: TableRow[] = [
        ...filteredRows.map((row) => ({
            fieldName: row.fieldName,
            value: row.value!
        }))
    ];

    return <GenericInformationTable title="Prouktkriterien" rows={rows} />;
}
