import { Routes } from "react-router-dom";
import { BookingRoutes } from "./BookingRoutes";
import ErrorRoutes from "./ErrorRoutes";
import WaitingListRoutes from "./WaitingListRoutes";
import AnfragenManagerCRMRoutes from "./AnfragenManagerCRMRoutes";

export default function AppRoutes() {
    return (
        <Routes>
            {BookingRoutes}
            {WaitingListRoutes}
            {AnfragenManagerCRMRoutes}
            {ErrorRoutes}
        </Routes>
    );
}
