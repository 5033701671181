import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import "../../../assets/styles/waitingListLead/WaitingListLeadTable.scss";
import { WaitingListLeadState } from "../types/enums";
import { BlurredTypograhy } from "../../../components/ui";
import { getModalTextsByProductType } from "../utils";

interface Props {
    firstName: string;
    lastName: string;
    productName: string;
    clientAvailability: string;
    requestZipCode: string;
    requestRegion: string;
    phoneNumber: string;
    waitingListLeadState: WaitingListLeadState;
    isClosedByClient: boolean;
    isPersonalProduct: boolean;
}

/**
 *
 * WaitingListLeadBasicInformationTable is a React component that displays a table containing key information about
 * a lead on a waiting list. It renders details such as
 * the client's name, phone number, product need, and the requested region. The visibility of sensitive information is
 * controlled based on the lead's state, with data either shown clearly or presented in a blurred format to protect privacy.
 *
 */
function WaitingListLeadBasicInformationTable({
    firstName,
    lastName,
    productName,
    clientAvailability,
    requestRegion,
    requestZipCode,
    phoneNumber,
    waitingListLeadState,
    isClosedByClient,
    isPersonalProduct
}: Readonly<Props>) {
    // Determines the title of the table based on the waiting list lead's state.
    const {
        approvedStateContactDetailsTableHeader,
        closedStateContactDetailsTableHeader,
        openCapacityStateProductionCriteriaDescription
    } = getModalTextsByProductType(isPersonalProduct);

    function getTableTitle(): string {
        if (waitingListLeadState === WaitingListLeadState.Acquired) {
            return approvedStateContactDetailsTableHeader;
        }
        return closedStateContactDetailsTableHeader;
    }

    // Returns either the actual information or a blurred representation based on the lead's state and other conditions.
    // This function helps in controlling the visibility of sensitive information.
    function getInformation(
        showIfRequestIsOpen: boolean,
        value: string,
        length?: number,
        isNumber?: boolean
    ): React.ReactNode {
        if (
            waitingListLeadState === WaitingListLeadState.Acquired ||
            (waitingListLeadState === WaitingListLeadState.Open &&
                !isClosedByClient &&
                showIfRequestIsOpen)
        ) {
            return <Typography>{value}</Typography>;
        }
        return <BlurredTypograhy length={length} isNumber={isNumber} />;
    }

    // Determines how to display the lead's name  title based on the acquired state and availability of first and last names.
    // Only in acuruired state, we get the actual name of the client.
    // In other states, we show general title
    function getClientNameTitle(): string {
        if (waitingListLeadState === WaitingListLeadState.Acquired) {
            if (firstName && lastName) {
                return "Name, Vorname:";
            } else if (firstName != null && firstName.trim() != "") {
                return "Vorname:";
            } else if (lastName != null && lastName.trim() != "") {
                return "Name:";
            }
        }
        return "Name, Vorname:";
    }

    // Returns the lead's name in a formatted manner or a blurred representation depending on the state.
    function getClientName(): React.ReactNode {
        if (waitingListLeadState === WaitingListLeadState.Acquired) {
            const name =
                firstName && lastName
                    ? `${lastName}, ${firstName}`
                    : firstName || lastName;
            return <Typography>{name}</Typography>;
        }
        return <BlurredTypograhy />;
    }

    return (
        <>
            {waitingListLeadState === WaitingListLeadState.Acquired &&
                !isClosedByClient && (
                    <h3 className="text-blue">Rufen Sie jetzt an:</h3>
                )}
            <div className="lead-table-container">
                <Paper className="lead-table">
                    <Box
                        className={`lead-table-title-box ${
                            waitingListLeadState ===
                                WaitingListLeadState.Acquired &&
                            !isClosedByClient
                                ? "green-box"
                                : "blue-box"
                        }`}
                    >
                        <h3 className="text-white">{getTableTitle()}</h3>
                    </Box>
                    <div aria-label="basic-information">
                        <div className="lead-table-row">
                            <div className="lead-table-cell">
                                <Typography>{getClientNameTitle()}</Typography>
                                {getClientName()}
                            </div>
                            <div className="lead-table-cell">
                                <Typography>Telefonnummer:</Typography>
                                {getInformation(false, phoneNumber, 10, true)}
                            </div>
                        </div>
                        <div className="lead-table-row">
                            <div className="lead-table-cell">
                                <Typography>Bedarf:</Typography>
                                {getInformation(true, productName)}
                            </div>
                            <div className="lead-table-cell">
                                <Typography>Bedarfsort:</Typography>
                                {getInformation(
                                    true,
                                    `${requestRegion}, ${requestZipCode}`
                                )}
                            </div>
                        </div>
                        <div className="lead-table-row">
                            <div className="lead-table-cell">
                                <Typography>Beste Erreichbarkeit:</Typography>
                                {getInformation(false, clientAvailability)}
                            </div>
                            <div className="lead-table-cell hidden-mobile"></div>
                        </div>
                    </div>
                </Paper>
                {waitingListLeadState === WaitingListLeadState.Open &&
                    !isClosedByClient && (
                        <p
                            className="text-black"
                            dangerouslySetInnerHTML={{
                                __html: openCapacityStateProductionCriteriaDescription
                            }}
                        ></p>
                    )}
            </div>
        </>
    );
}

export default WaitingListLeadBasicInformationTable;
