import { createContext, useContext } from "react";

// Create the context for supplierName
export const SupplierContext = createContext<string | undefined>(undefined);

// Create a custom hook to access the context value
export const useSupplierName = () => {
    const context = useContext(SupplierContext);
    if (context === undefined) {
        throw new Error("useSupplierName must be used within a SupplierContext.Provider");
    }
    return context;
};
