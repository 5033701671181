import { rest } from "msw";
import { IWaitingListLeadBasicInfo } from "../services/dtos";
import { WaitingListLeadState } from "../types/enums";
import { PhoneSort } from "../../../types/enums";
import { ILeadReceiptsInfo } from "../../PreLaunchRegistration/service/dtos";

export const waitingListLeadHandlers = [

    rest.get("/WaitingListLeads/:secureId/basicInfo", (req, res, ctx) => {
        const { secureId } = req.params;
        let mockData: IWaitingListLeadBasicInfo;
        if (secureId === "openId") {
            mockData = {
                waitingListLeadState: WaitingListLeadState.Open,
                waitingListLeadNumber: "123456",
                firstName: "",
                lastName: "",
                clientAvailability: "",
                productName: "Product A",
                requestZipCode: "12345",
                requestRegion: "Region X",
                isClosedByClient: false,
                isPersonalProduct : false,
                phoneNumbers: []
            };
        } else if (secureId === "acquiredId") {
            mockData = {
                waitingListLeadState: WaitingListLeadState.Acquired,
                waitingListLeadNumber: "123456",
                firstName: "John",
                lastName: "Doe",
                clientAvailability: "9 AM - 5 PM",
                productName: "Product A",
                requestZipCode: "12345",
                requestRegion: "Region X",
                isClosedByClient: false,
                isPersonalProduct : false,
                phoneNumbers: [
                    {
                        type: PhoneSort.Mobile,
                        internationalNoBlanks: "+491749593259"
                    }
                ]
            };
        } else {
            mockData = {
                waitingListLeadState: WaitingListLeadState.Open,
                waitingListLeadNumber: "123456",
                firstName: "",
                lastName: "",
                clientAvailability: "",
                productName: "Product A",
                requestZipCode: "",
                requestRegion: "",
                isClosedByClient: true,
                isPersonalProduct : false,
                phoneNumbers: []
            };
        }

        return res(ctx.status(200), ctx.json(mockData));
    }),
    
    rest.get("/WaitingListLeads//receiptsInfo", (req, res, ctx) => {
        const { supplierId } = req.params;
        let mockData: ILeadReceiptsInfo;
        mockData = {
            waitingListBookingLeadReceipts : [
                {         
                    id : "1",      
                    firstName : "Test",
                    surName : "Test",
                    email : "Test@gmail.com",
                    mobileNumber : {
                        number : "015752814339",
                        isSelected : true
                    }
                }
            ]
        };
        return res(ctx.status(200), ctx.json(mockData));
    }),

    rest.get("/WaitingListLeads/:supplierId/criteriaInfo", (req, res, ctx) => {
        const { secureId ,supplierId } = req.params;
        return res(ctx.status(200));
    })
];
