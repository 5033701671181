export function getZipCodeLinkForWaitingListDetailsPage(
    supplierId: string | undefined,
    secureId: string
): string {
    // If `supplierId` is defined, it indicates the use of the old waiting list details UI.
    // In the current UI, `supplierId` is no longer included in the URL.
    // Instead, only the `secureId` is used, and the relevant data is retrieved from the backend using the `secureId`.
    if (supplierId != undefined) {
        return `/${supplierId}/waitinglistrequests/${secureId}/zipcodeform`;
    } else {
        return `/waiting-list-lead-details/${secureId}/zip-code`;
    }
}
