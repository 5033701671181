import { Box, Button, FormControl, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { IAreaInformation } from "../types";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";
import { useState } from "react";
import { useGetZipCodesByStartsWith } from "../services/zipCodeService";
import ZipCodeSearchResultDetails from "./ZipCodeSearchResultDetails";
import ZipCodeModalActions from "./ZipCodeModalActions";
import { generateUUIDFromTimestamp } from "utils";
import { GermanCountryIso2 } from "../../../constants";

interface Props {
    handleZipCodeGroupAdd: (areaInformation: IAreaInformation) => void;
    handleModalCloseEvent: () => void;
}

interface FormData {
    zipCodePrefix: string;
}

/*
 * ZipCodeByStartWith is a React component that allows the user to search for a group of zip codes
 * starting with a given prefix. The callback function handleZipCodeGroupAdd
 * is called whenever the user adds an to the group to update the list of postal codes and potential number.
 */
function ZipCodeByStartWith({
    handleZipCodeGroupAdd,
    handleModalCloseEvent
}: Readonly<Props>) {
    const [zipCodePrefix, setZipCodePrefix] = useState("");
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>();
    const { data: searchInfo } = useGetZipCodesByStartsWith(
        zipCodePrefix,
        GermanCountryIso2,
        {
            enabled: zipCodePrefix.length > 0 && isSearchTriggered
        }
    );

    function onZipCodePrefixInputChange() {
        setIsSearchTriggered(false);
    }

    function onSubmit(formData: FormData) {
        setZipCodePrefix(formData.zipCodePrefix);
        setIsSearchTriggered(true);
    }

    function zipCodeGroupAddClick() {
        if (searchInfo === undefined || searchInfo.length === 0) return;
        handleZipCodeGroupAdd({
            areaId: generateUUIDFromTimestamp(),
            name: `PLZ ${zipCodePrefix}*`,
            totalNumberOfZipCode: searchInfo.length,
            zipCodeList: searchInfo.map((item) => item.zipCode)
        });
    }

    function renderSearchResults() {
        if (searchInfo === undefined || !isSearchTriggered) {
            return null;
        } else if (searchInfo.length === 0) {
            return (
                <div className="error-message">
                    {`Keine Postleitzahlen für ${zipCodePrefix}* -DE gefunden.`}
                </div>
            );
        } else {
            return (
                <ZipCodeSearchResultDetails
                    zipCodeList={searchInfo.map((item) => item.zipCode)}
                />
            );
        }
    }

    return (
        <div>
            <div className="highlight-blue">Details hinterlegen:</div>
            <p className="zipcode-input-heading">Anfänge von Postletzahlen</p>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                className="zipcode-input"
            >
                <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                    <TextField
                        label="Postleitzahl angeben"
                        variant="outlined"
                        error={Boolean(errors.zipCodePrefix)}
                        {...register("zipCodePrefix", {
                            onChange: () => {
                                onZipCodePrefixInputChange();
                            },
                            required:
                                "Bitte geben Sie eine gültige Postleitzahl ein."
                        })}
                    />
                    {errors.zipCodePrefix && (
                        <div className="error-message">{`${errors.zipCodePrefix.message}`}</div>
                    )}
                </FormControl>
                <div className="divider">*</div>
                <Button type="submit" variant="outlined">
                    Suchen
                </Button>
            </Box>
            {renderSearchResults()}
            <ZipCodeModalActions
                disabled={!(searchInfo?.length && isSearchTriggered)}
                zipCodeGroupAddClick={zipCodeGroupAddClick}
                modalCloseButtonClick={handleModalCloseEvent}
            />
        </div>
    );
}

export default ZipCodeByStartWith;
