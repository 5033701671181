import { LeadsQueryResultDto } from "./dtos";
import { useQuery } from "@tanstack/react-query";
import { Product } from "types/product";
import { GetAllLeadProducts, GetAllLeads } from "constants/reactQueryKey";
import { AnfragenManagerClient, get } from "lib/httpService";

export const useGetAllLeads = (
  pageSize: number,
  pageIndex: number,
  nameSearchText: string,
  anfragenNumberSearchText: string,
  leadStates: number[],
  types: number[],
  products:number[],
  sortField: string,
  sortOrder: 'asc' | 'desc',
  options = {}
) => {
  return useQuery(
    [
      GetAllLeads,
      pageSize,
      pageIndex,
      nameSearchText,
      anfragenNumberSearchText,
      leadStates,
      types,
      products,
      sortField,
      sortOrder,
    ],
    async () => {
      // Construct the URL with query parameters safely
      const params = new URLSearchParams({
        pageSize: pageSize.toString(),
        pageIndex: pageIndex.toString(),
        anfragenNumberSearchText,
        nameSearchText,
      });

      // Append arrays as multiple parameters
      leadStates.forEach((state) => params.append('leadStates', state.toString()));
      types.forEach((type) => params.append('type', type.toString()));
      products.forEach((product) => params.append('products', product.toString()));

      // Append sorting parameters
      if (sortField) {
        params.append('sortField', sortField);
      }
      if (sortOrder) {
        params.append('sortOrder', sortOrder);
      }

      const url = `/lead/GetAllLeads?${params.toString()}`;
      return await get<LeadsQueryResultDto>(url, AnfragenManagerClient);
    },
    {
      ...options,
    }
  );
};

export const useGetAllProduct = () => {
    return useQuery([GetAllLeadProducts], async () => {
      const url = `/lead/GetAllProducts`; 
      return await get<Product[]>(url, AnfragenManagerClient);
    });
};

