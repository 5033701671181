import { GetDeutscheSeniorenPortalLeadDetails } from "constants/reactQueryKey";
import { IDeutscheSeniorenPortalLeadDetails } from "./dtos";
import { AnfragenManagerClient, get } from "lib/httpService";
import { useQuery } from "@tanstack/react-query";

/**
 * @function useGetDeutscheSeniorenPortalLeadDetails
 * @description Custom hook to fetch Deutsche Senioren Portal lead details using a secure ID.
 * @param {string} secureId - The secure ID of the lead.
 * @param {object} options - Optional configuration for the query.
 * @returns {object} - The result of the query.
 */
export const useGetDeutscheSeniorenPortalLeadDetails = (
    secureId: string,
    options = {}
) => {
    return useQuery(
        [GetDeutscheSeniorenPortalLeadDetails, secureId],
        async () => {
            return await get<IDeutscheSeniorenPortalLeadDetails>(
                `/external-provider/DeutschesSeniorenportal/GetDspLeadById/${secureId}`, AnfragenManagerClient
            );
        },
        {
            ...options
        }
    );
};