import { IPreLunchRegistrationFormData } from "../components/PreLunchRegistrationForm";
import { ILeadReceipt } from "../service/dtos";

export function convertLeadReceiptToFormData(
    contact: ILeadReceipt | undefined
): IPreLunchRegistrationFormData | undefined {
    if (contact === undefined) return undefined;
    return {
        name:
            contact.firstName || contact.surName
                ? (contact.firstName ?? "") + " " + (contact.surName ?? "")
                : "",
        emailAddress: contact.email ?? "",
        mobileNumber: contact.mobileNumber.number ?? "",
        companyName: ""
    };
}
