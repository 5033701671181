import { Box, Paper, Typography } from "@mui/material";
import "../../../assets/styles/waitingListLead/WaitingListLeadTable.scss";
import { WaitingListLeadState } from "../types/enums";
import { BlurredTypograhy } from "../../../components/ui";
import React from "react";
import { IProductCriteriaInfo } from "../services/dtos";
import { getModalTextsByProductType } from "../utils";

/**
 * WaitingListLeadProductCriteriaInformationTable is a React component that displays product criteria information associated with a waiting list lead. The display
 * of information is conditional on the lead's state, showing details clearly if the lead is acquired or open, and blurred
 * if otherwise to protect privacy.
 */
interface WaitingListLeadProductCriteriaInformationTableV1Props {
    waitingListLeadCriteriaInfo: {
        waitingListLeadState: WaitingListLeadState;
        isClosedByClient: boolean;
        isPersonalProduct: boolean;
        productCriteriaList: IProductCriteriaInfo[];
    };
}

function WaitingListLeadProductCriteriaInformationTableV1({
    waitingListLeadCriteriaInfo
}: WaitingListLeadProductCriteriaInformationTableV1Props) {
    // Renders the information based on the lead's state, displaying it directly if acquired, or blurred otherwise.
    function getInformation(
        waitingListLeadState: WaitingListLeadState,
        isClosedByClient: boolean,
        value: string
    ): React.ReactNode {
        if (
            waitingListLeadState === WaitingListLeadState.Acquired ||
            (!isClosedByClient &&
                waitingListLeadState === WaitingListLeadState.Open)
        ) {
            return <Typography>{value}</Typography>;
        }
        return <BlurredTypograhy />;
    }

    // Dynamically generates table rows for each product criteria. It pairs criteria in twos for a two-column layout.
    function generateTableRows(
        waitingListLeadState: WaitingListLeadState,
        isClosedByClient: boolean,
        productCriterias: IProductCriteriaInfo[]
    ): React.ReactNode[] {
        const rows = [];
        for (let i = 0; i < productCriterias.length; i += 2) {
            const current = productCriterias[i];
            const next = productCriterias[i + 1];

            rows.push(
                <div className="lead-table-row" key={i}>
                    <div className="lead-table-cell">
                        <Typography>{current.criteriaName}:</Typography>
                        {getInformation(
                            waitingListLeadState,
                            isClosedByClient,
                            current.value
                        )}
                    </div>
                    {next && (
                        <div className="lead-table-cell">
                            <Typography>{next.criteriaName}:</Typography>
                            {getInformation(
                                waitingListLeadState,
                                isClosedByClient,
                                next.value
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return rows;
    }

    return (
        <>
            {waitingListLeadCriteriaInfo && (
                <Paper className="lead-table">
                    <Box
                        className={`lead-table-title-box ${
                            waitingListLeadCriteriaInfo.waitingListLeadState ===
                                WaitingListLeadState.Acquired &&
                            !waitingListLeadCriteriaInfo.isClosedByClient
                                ? "green-box"
                                : "blue-box"
                        }`}
                    >
                        <h3 className="text-white">
                            {
                                getModalTextsByProductType(
                                    waitingListLeadCriteriaInfo.isPersonalProduct
                                ).approvedStateProductCriteriaDetailsTableHeader
                            }
                        </h3>
                    </Box>
                    <div aria-label="waiting-list-criteria-information">
                        {generateTableRows(
                            waitingListLeadCriteriaInfo.waitingListLeadState,
                            waitingListLeadCriteriaInfo.isClosedByClient,
                            waitingListLeadCriteriaInfo.productCriteriaList
                        )}
                    </div>
                </Paper>
            )}
        </>
    );
}

export default WaitingListLeadProductCriteriaInformationTableV1;
