import React, { useCallback, useState } from "react";
import { Checkbox, FormControl, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import _ from "lodash";
import { getAllLeadType, getLeadTypeLabel } from "types/leadType";
import { getProductDisplayName } from "types/product";
import { getAllLeadStatus, getLeadStatus } from "types/stateStatus";
import { useGetAllProduct } from "../service/LeadOverviewService";

interface LeadOverviewFiltersProps {
  onStatusChange: (statuses: number[]) => void;
  onLeadTypeChange: (leadTypes: number[]) => void;
  onProductChange: (products: number[]) => void;
  onNameSearchChange: (searchText: string) => void;
  onAnfragenNumberSearchChange: (searchText: string) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const LeadOverviewFilters: React.FC<LeadOverviewFiltersProps> = ({
  onStatusChange,
  onLeadTypeChange,
  onProductChange,
  onNameSearchChange,
  onAnfragenNumberSearchChange,
  setPage,
}) => {
  const [selectedStatusItems, setSelectedStatusItems] = useState<number[]>([]);
  const [selectedLeadTypeItems, setSelectedLeadTypeItems] = useState<number[]>([]);
  const [selectedProductItems, setSelectedProductItems] = useState<number[]>([]);
  const [nameSearchText, setNameSearchText] = useState<string>("");
  const [anfragenSearchText, setAnfragenSearchText] = useState<string>("");

  const { data: productInfo } = useGetAllProduct();

  const handleStatusChange = (event: any) => {
    const { target: { value } } = event;
    const statuses = typeof value === "string" ? value.split(",").map(Number) : value;
    setSelectedStatusItems(statuses);
    onStatusChange(statuses);
    setPage(0);
  };

  const handleLeadTypeChange = (event: any) => {
    const { target: { value } } = event;
    const leadTypes = typeof value === "string" ? value.split(",").map(Number) : value;
    setSelectedLeadTypeItems(leadTypes);
    onLeadTypeChange(leadTypes);
    setPage(0);
  };

  const handleProductChange = (event: any) => {
    const { target: { value } } = event;
    const products = typeof value === "string" ? value.split(",").map(Number) : value;
    setSelectedProductItems(products);
    onProductChange(products);
    setPage(0);
  };

  const handleNameSearchInput = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setNameSearchText(value);
      onNameSearchChange(value);
      setPage(0);
    }, 500),
    [onNameSearchChange, setPage]
  );

  const handleAnfagenSearchSearchInput = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setAnfragenSearchText(value);
      onAnfragenNumberSearchChange(value);
      setPage(0);
    }, 500),
    [onAnfragenNumberSearchChange, setPage]
  );

  return (
    <div className="toolbar">
      <div className="filter">
        <div className="box-style">
          <div className="icon-search">
            <span className="icons-search"></span>
          </div>
          <div className="search-input">
            <FormControl fullWidth>
              <TextField
                placeholder="Nach Anfragen-Nummer suchen"
                onChange={handleAnfagenSearchSearchInput}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className="filter">
        <div className="box-style">
          <div className="icon-search">
            <span className="icons-search"></span>
          </div>
          <div className="search-input">
            <FormControl fullWidth>
              <TextField
                placeholder="Nach Name suchen"
                onChange={handleNameSearchInput}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }}
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className="dropdown">
        <FormControl fullWidth>
          <Select
            multiple
            value={selectedProductItems}
            onChange={handleProductChange}
            displayEmpty
            renderValue={(selected) =>
              selected.length
                ? selected.map((id) => getProductDisplayName(id)).join(", ")
                : "Bedarf"
            }
          >
            {productInfo &&
              productInfo.map((product: any) => (
                <MenuItem key={product} value={product}>
                  <Checkbox checked={selectedProductItems.includes(product)} />
                  <ListItemText primary={getProductDisplayName(product)} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="mr-15 dropdown">
        <FormControl fullWidth>
          <Select
            multiple
            value={selectedLeadTypeItems}
            onChange={handleLeadTypeChange}
            displayEmpty
            renderValue={(selected) =>
              selected.length
                ? selected.map((id) => getLeadTypeLabel(id)).join(", ")
                : "Herkunft"
            }
          >
            {getAllLeadType().map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={selectedLeadTypeItems.includes(type)} />
                <ListItemText primary={getLeadTypeLabel(type)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="status-filter dropdown">
        <FormControl fullWidth>
          <Select
            multiple
            value={selectedStatusItems}
            onChange={handleStatusChange}
            displayEmpty
            renderValue={(selected) =>
              selected.length
                ? selected.map((id) => getLeadStatus(id)).join(", ")
                : "Status"
            }
          >
            {getAllLeadStatus().map((status) => (
              <MenuItem key={status} value={status}>
                <Checkbox checked={selectedStatusItems.includes(status)} />
                <ListItemText primary={getLeadStatus(status)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      
    </div>
  );
};

export default LeadOverviewFilters;
