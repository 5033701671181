import GenericInformationTable, { TableRow } from "lib/table/GenericInformationTable";

interface PartnerInformationInfoProps {
    firstName: string | undefined;
    lastName: string | undefined;
    dateOfBirth: string | undefined;
    typeOfCare: string | undefined;
    careLevel: string | undefined;
}

/**
 * DeutscheSeniorenPortalLeadSeniorInformationTable renders key lead information using the GenericInformationTable component.
 */
export function DeutscheSeniorenPortalLeadPartnerInformationTable({
    firstName,
    lastName,
    dateOfBirth,
    typeOfCare,
    careLevel
}: Readonly<PartnerInformationInfoProps>) {
    const rows: TableRow[] = [
        { fieldName: "Name, Vorname:", value: firstName && lastName ? `${lastName}, ${firstName}` : firstName || lastName },
        { fieldName: "Geburtsdatum:", value: dateOfBirth }, 
        { fieldName: "Pflegeart:", value: typeOfCare }, 
        { fieldName: "Pflegegrad:", value: careLevel },
    ];

    return <GenericInformationTable title="Angabe des Partners" rows={rows} />;
}