import Cookies from "js-cookie";

export const setCookieData = (key: string, value: string) => {
    Cookies.set(key, value);
};

export const getDataByCookie = (key: string) => {
    return Cookies.get(key) || "";
};

export const removeCookieData = (key: string) => {
    Cookies.remove(key);
};
