import "../../../assets/styles/waitingListLead/WaitingListLeadState.scss";
import { getModalTextsByProductType } from "../utils";

/**
 *
 * WaitingListLeadStateAcquired is a React component that is designed
 * to inform users that they have successfully reported available
 * capacity and can now access client contact details.
 *
 */

interface Props {
    isPersonalProduct: boolean;
}


function WaitingListLeadStateAcquiredV1({isPersonalProduct}:Readonly<Props>){

    const { 
        approvedStateTopMessage,
        approvedStateGreenBoxList1,
        approvedStateGreenBoxList2,
        approvedStateGreenBoxTip,
    } = getModalTextsByProductType(isPersonalProduct); 

    return (
        <div className="waiting-list-lead-acquired-container">
            <div className="flex-nowrap align-center mb-20">
                <div className="icon icon-check"></div>
                <h3 className="highlight-green">
                    {approvedStateTopMessage}
                </h3>
            </div>

            <div className="waiting-list-lead-status-box acquired-box mb-20">
                <div>
                    <h3>Ihre nächsten Schritte:</h3>
                    <ol>
                        <li dangerouslySetInnerHTML={{ __html: approvedStateGreenBoxList1 }}></li>
                        <li dangerouslySetInnerHTML={{ __html: approvedStateGreenBoxList2 }}></li>
                    </ol>
                </div>
                <div>
                    <i>
                        <u>Unser Tipp:</u> {approvedStateGreenBoxTip}
                    </i>
                </div>
            </div>
        </div>
    );
}

export default WaitingListLeadStateAcquiredV1;
