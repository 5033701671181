import {
    DataGrid,
    GridFooterContainer,
    GridPagination,
    GridRenderCellParams,
    GridSortModel
} from "@mui/x-data-grid";
import { useGetAllLeads } from "../service/LeadOverviewService";
import { useEffect, useState } from "react";
import { getProductDisplayName, Product } from "types/product";
import { useNavigate } from "react-router-dom";
import { getLeadStatus, getLeadStatusClass } from "types/stateStatus";
import { getLeadTypeLabel, LeadType } from "types/leadType";
import _ from "lodash";
import LeadOverviewFilters from "./LeadOverviewFilters";
import "./../../../assets/styles/leadOverview/leadOverview.scss";
import CustomColumnHeader from "./CustomTableHeader";

const LeadOverview = () => {
    interface RowData {
        id: number;
        leadNumber: string;
        product: number;
        location: string;
        soldDate: string;
        fullName: string;
        origin: number;
        secureId: string;
        status: string;
    }

    const [selectedStatusItems, setSelectedStatusItems] = useState<number[]>(
        []
    );
    const [selectedLeadTypeItems, setSelectedLeadTypeItems] = useState<
        number[]
    >([]);
    const [selectedProductItems, setSelectedProductItems] = useState<number[]>(
        []
    );

    const [nameSearchText, setNameSearchText] = useState<string>("");
    const [anfragenNumberSearchText, setAnfragenNumberSearchText] =
        useState<string>("");

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rowCount, setRowCount] = useState(0);
    const [rows, setRows] = useState<RowData[]>([]);
    const [sortModel, setSortModel] = useState<any[]>([]);
    const [loading, setLoading] = useState(false); // Loading state
    const CustomNoRowsOverlay = () => <div />; // Empty component

    // Fetch data whenever pagination, filters, or sorting changes
    const {
        data: leadReceiptsInfo,
        isSuccess,
        isFetching
    } = useGetAllLeads(
        pageSize,
        page,
        nameSearchText,
        anfragenNumberSearchText,
        selectedStatusItems,
        selectedLeadTypeItems,
        selectedProductItems,
        sortModel.length > 0 ? sortModel[0].field : "soldDate",
        sortModel.length > 0 ? sortModel[0].sort : "desc"
    );

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(isFetching);
        if (isSuccess && leadReceiptsInfo) {
            const rows = leadReceiptsInfo.leadOverviews.map((lead: any) => ({
                id: lead.leadId,
                soldDate: lead.updatedDateUtc,
                fullName: `${lead.lastName}, ${lead.firstName} `,
                origin: lead.type,
                status: lead.state,
                leadNumber: lead.leadNumber,
                secureId: lead.secureId,
                product: lead.product,
                location: `${lead.zipCode} ${lead.city}`
            }));

            setRows(rows);
            setRowCount(leadReceiptsInfo.totalDataCount);
        }
    }, [isSuccess, leadReceiptsInfo, isFetching]);

    const handleNavigate = (secureId: string, type: LeadType) => {
        if (type == LeadType.WaitingList) {
            navigate("/waiting-list-lead-details/" + secureId);
        } else if (type == LeadType.WohnenImAlter) {
            navigate("/wohnen-im-alter-lead-details/" + secureId);
        } else if (type == LeadType.DeutschesSeniorenportal) {
            navigate("/deutsche-senioren-portal-lead-details/" + secureId);
        }
        if (type == LeadType.Capacity) {
        }
        if (type == LeadType.EntlassManager) {
        }
    };

    const handleSortModelChange = (model: GridSortModel) => {
        setSortModel(model);
        setPage(0);
    };

    const columns = [
        {
            field: "leadNumber",
            headerName: "Anfragen-Nummer",
            flex: 0.1,
            renderHeader: () => (
                <CustomColumnHeader
                    title="Anfragen-Nummer"
                    field="leadNumber"
                    sortModel={sortModel}
                />
            ),
            sortable: false
        },
        {
            field: "soldDate",
            headerName: "Anfrage-Datum",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Anfrage-Datum"
                    field="soldDate"
                    sortModel={sortModel}
                />
            ),
            flex: 0.14,

            renderCell: (params: GridRenderCellParams<Date>) =>
                new Date(params.value as Date).toLocaleString("de-DE", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                }),
            sortable: true
        },
        {
            field: "fullName",
            headerName: "Name",
            flex: 0.14,
            renderHeader: () => (
                <CustomColumnHeader
                    title="Name"
                    field="fullName"
                    sortModel={sortModel}
                />
            ),
            renderCell: (params: GridRenderCellParams<string>) => {
                if (params.value === ",  ") {
                    return (
                        <div className="flex-wrap align-center">
                            <div className="flex-wrap align-center">
                                <p className="MuiTypography-root MuiTypography-body1 blurryText undefined css-9l3uo3">
                                    Mustermann Max
                                </p>
                                <div className="icon icon-lock"></div>
                            </div>
                        </div>
                    );
                }
                return params.value;
            }
        },
        {
            field: "product",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Bedarf"
                    field="product"
                    sortModel={sortModel}
                />
            ),
            renderCell: (params: any) => (
                <span>{getProductDisplayName(params.value)}</span>
            ),
            headerName: "Bedarf",
            flex: 0.14,
            sortable: false
        },
        {
            field: "location",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Bedarfsort"
                    field="location"
                    sortModel={sortModel}
                />
            ),
            renderCell: (params: GridRenderCellParams<string>) => {
                if (params.value === " ") {
                    return (
                        <div className="flex-wrap align-center">
                            <div className="flex-wrap align-center">
                                <p className="MuiTypography-root MuiTypography-body1 blurryText undefined css-9l3uo3">
                                    No Region
                                </p>
                                <div className="icon icon-lock"></div>
                            </div>
                        </div>
                    );
                }
                return params.value;
            },
            headerName: "Bedarfsort",
            flex: 0.15,
            sortable: false
        },
        {
            field: "origin",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Herkunft"
                    field="origin"
                    sortModel={sortModel}
                />
            ),
            headerName: "Herkunft",
            flex: 0.14,
            renderCell: (params: any) => (
                <span>{getLeadTypeLabel(params.value)}</span>
            ),
            sortable: false
        },
        {
            field: "status",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Status"
                    field="Status"
                    sortModel={sortModel}
                />
            ),
            headerName: "Status",
            flex: 0.1,
            sortable: false,
            renderCell: (params: any) => {
                const statusClass = getLeadStatusClass(params.value);
                return (
                    <span className={statusClass}>
                        {getLeadStatus(params.value)}
                    </span>
                );
            }
        },
        {
            field: "secureId",
            headerName: "Zum Kontakt",
            renderHeader: () => (
                <CustomColumnHeader
                    title="Zum Kontakt"
                    field="secureId"
                    sortModel={sortModel}
                />
            ),
            flex: 0.09,
            sortable: false,
            renderCell: (params: any) => (
                <button
                    className="to-client"
                    onClick={() =>
                        handleNavigate(params.value, params.row.origin)
                    }
                >
                    <span className="icons-right-open pr-5"></span>
                    <span className="icons-user"></span>
                </button>
            )
        }
    ];

    // Custom Footer Component
    const CustomFooter = () => (
        <GridFooterContainer>
            <GridPagination
                labelDisplayedRows={({ from, to, count }) =>
                    `${from}–${to} von ${count}`
                }
                labelRowsPerPage="Klienten pro Seite:"
            />
        </GridFooterContainer>
    );

    return (
        <div className="waiting-list-lead-container">
            <h1 className="text-blue mb-50">Klienten</h1>
            <div className="toolbar">
                <LeadOverviewFilters
                    setPage={setPage}
                    onStatusChange={(statuses: number[]) =>
                        setSelectedStatusItems(statuses)
                    }
                    onLeadTypeChange={(leadTypes: LeadType[]) =>
                        setSelectedLeadTypeItems(leadTypes)
                    }
                    onProductChange={(products: Product[]) =>
                        setSelectedProductItems(products)
                    }
                    onNameSearchChange={(searchText: string) => {
                        setNameSearchText(searchText);
                    }}
                    onAnfragenNumberSearchChange={(searchText: string) => {
                        setAnfragenNumberSearchText(searchText);
                    }}
                />
            </div>

            <div className="data-grid-container">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowCount={rowCount}
                    autoHeight={true}
                    loading={loading}
                    rowHeight={45}
                    sortingOrder={["asc", "desc"]}
                    rowsPerPageOptions={[5, 10, 20]}
                    paginationMode="server"
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    components={{
                        Footer: CustomFooter,
                        NoRowsOverlay: CustomNoRowsOverlay // Override the NoRowsOverlay
                    }}
                    disableSelectionOnClick
                    disableColumnMenu
                />
            </div>
        </div>
    );
};

export default LeadOverview;
