import { useQuery } from "@tanstack/react-query";
import { get } from "../../../lib";
import { ILeadReceiptsInfo } from "./dtos";
import { getLeadReceiptsInfo } from "../../../constants/reactQueryKey";
export const useLeadReceiptsInfo = (supplierId: string, options = {}) => {
    return useQuery(
        [getLeadReceiptsInfo, supplierId],
        async () => {
            return await get<ILeadReceiptsInfo>(
                `/WaitingListLeads/${supplierId}/receiptsInfo`
            );
        },
        {
            ...options
        }
    );
};
