import { EmailRegex } from "../../../constants";
import { isValidPhoneNumber } from "../../../utils";

interface ValidationRulesProps {
    isMobileNumberActive?: boolean;
    isFaxNumberActive?: boolean;
}

export const useAMPreRegistrationFormValidationRules =
    ({}: Readonly<ValidationRulesProps>) => {
        const emailAddressRules = {
            required: "E-Mail-Adresse ist erforderlich",
            pattern: {
                value: EmailRegex,
                message: "Keine gültige E-Mail-Adresse"
            }
        };

        const mobileNumberRules = {
            required: false,
            validate: (value: string) =>
                value.trim() === "" ||
                isValidPhoneNumber(value) ||
                "Keine gültige Telefonnummer"
        };

        return {
            emailAddressRules,
            mobileNumberRules
        };
    };
