import { Box, Button, FormControl, TextField } from "@mui/material";
import { IAreaInformation } from "../types";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
    useGetZipCodesByRadius,
    isZipCodeValid
} from "../services/zipCodeService";
import { useQueryClient } from "@tanstack/react-query";
import ZipCodeModalActions from "./ZipCodeModalActions";
import ZipCodeSearchResultDetails from "./ZipCodeSearchResultDetails";
import { generateUUIDFromTimestamp } from "utils";
import { GermanCountryIso2 } from "../../../constants";

interface Props {
    handleZipCodeGroupAdd: (areaInformation: IAreaInformation) => void;
    handleModalCloseEvent: () => void;
}

interface FormData {
    zipCode: string;
    radius: number;
}

/**
 * ZipCodeByRadius is a React component that component allows the user to search for a group of zip codes
 * within a specified radius of a given zip code. The callback function handleZipCodeGroupAdd
 * is called whenever the user adds an to the group to update the list of postal codes and potential number.
 */
function ZipCodeByRadius({
    handleZipCodeGroupAdd,
    handleModalCloseEvent
}: Readonly<Props>) {
    const [zipCodeArea, setZipCodeArea] = useState({
        zipCode: "",
        radius: 0
    });
    const queryClient = useQueryClient();
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>({
        mode: "onBlur"
    });
    const { data: searchInfo } = useGetZipCodesByRadius(
        zipCodeArea.zipCode,
        zipCodeArea.radius,
        GermanCountryIso2,
        {
            enabled:
                zipCodeArea.zipCode.length > 0 &&
                zipCodeArea.radius > 0 &&
                isSearchTriggered
        }
    );

    function onSubmit(formData: FormData) {
        setZipCodeArea({
            zipCode: formData.zipCode,
            radius: formData.radius
        });
        setIsSearchTriggered(true);
    }

    function onInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        setIsSearchTriggered(false);
    }

    function zipCodeGroupAddClick() {
        if (searchInfo === undefined || searchInfo.length === 0) return;
        handleZipCodeGroupAdd({
            areaId: generateUUIDFromTimestamp(),
            name: `${zipCodeArea.radius} km um PLZ ${zipCodeArea.zipCode}`,
            totalNumberOfZipCode: searchInfo.length,
            zipCodeList: searchInfo.map((item) => item.zipCode)
        });
    }

    function renderSearchResults() {
        if (searchInfo === undefined || !isSearchTriggered) {
            return null;
        } else if (searchInfo.length === 0) {
            return (
                <div className="error-message">
                    Die Postleitzahl-Gruppe konnte nicht gespeichert werden.
                    Bitte überprüfen Sie Ihre Eingabe.
                </div>
            );
        } else {
            return (
                <ZipCodeSearchResultDetails
                    zipCodeList={searchInfo.map((item) => item.zipCode)}
                />
            );
        }
    }

    return (
        <div>
            <div className="highlight-blue">Details hinterlegen:</div>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                className="flex-wrap space-between"
            >
                <div className="w-250">
                    <p className="zipcode-input-heading">Postletzahlen</p>
                    <Box className="zipcode-input">
                        <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                            <TextField
                                label="Postleitzahl angeben"
                                variant="outlined"
                                error={Boolean(errors.zipCode)}
                                {...register("zipCode", {
                                    onChange: onInputChange,
                                    required: true,
                                    validate: async (val) => {
                                        try {
                                            return await isZipCodeValid(
                                                queryClient,
                                                val
                                            );
                                        } catch {
                                            return false;
                                        }
                                    }
                                })}
                            />
                        </FormControl>
                    </Box>
                </div>
                <div>
                    <p className="zipcode-input-heading">Radius</p>
                    <Box className="zipcode-input">
                        <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                            <TextField
                                label="Radius angeben"
                                variant="outlined"
                                error={Boolean(errors.radius)}
                                {...register("radius", {
                                    onChange: onInputChange,
                                    required: true,
                                    validate: (val) => {
                                        return val > 0;
                                    }
                                })}
                            />
                        </FormControl>
                        <div className="divider">km</div>
                        <Button type="submit" variant="outlined">
                            Suchen
                        </Button>
                    </Box>
                </div>
            </Box>
            {(errors.zipCode || errors.radius) && (
                <div className="error-message">
                    Bitte geben Sie eine gültige Postleitzahl und Radius ein.
                </div>
            )}
            {renderSearchResults()}
            <ZipCodeModalActions
                disabled={!(searchInfo?.length && isSearchTriggered)}
                zipCodeGroupAddClick={zipCodeGroupAddClick}
                modalCloseButtonClick={handleModalCloseEvent}
            />
        </div>
    );
}

export default ZipCodeByRadius;
