import { useParams } from "react-router-dom";
import { useEffect } from "react";
import BackToTopButton from "components/ui/BackToTopButton";
import { useGetDeutscheSeniorenPortalLeadDetails } from "../services/DeutscheSeniorenPortalLeadService";
import DeutscheSeniorenPortalLeadAcquired from "./DeutscheSeniorenPortalLeadAcquired";
import { getProductDisplayName } from "types/product";
import { DeutscheSeniorenPortalLeadClientInformationTable } from "./DeutscheSeniorenPortalLeadClientInformationTable";
import { DeutscheSeniorenPortalLeadSeniorInformationTable } from "./DeutscheSeniorenPortalLeadSeniorInformationTable";
import { getCareLevelDisplayName } from "types/careLevel";
import { DeutscheSeniorenPortalLeadProductCriteriaTable } from "./DeutscheSeniorenPortalLeadProductCriteriaTable";
import { DeutscheSeniorenPortalLeadPartnerInformationTable } from "./DeutscheSeniorenPortalLeadPartnerInformationTable";

const DeutscheSeniorenPortalLeadOverview = () => {
    const { secureId } = useParams() as { secureId: string };

    const {
        data: deutscheSeniorenPortalDetailsInfo,
        isSuccess: deutscheSeniorenPortalSuccess,
        isError: deutscheSeniorenPortalError,
        error: deutscheSeniorenPortalOnError
    } = useGetDeutscheSeniorenPortalLeadDetails(secureId);

    useEffect(() => {
        // useEffect hook to perform side effects. In this case, it checks if an API call resulted in an error.
        // If there is an error, specifically a 404 error, it navigates the user to a 404 page.
        // The dependency array includes isSuccess and isError to only rerun this effect when these values change.
        console.log(deutscheSeniorenPortalDetailsInfo);
    }, [deutscheSeniorenPortalOnError, deutscheSeniorenPortalSuccess]);

    return (
        <div className="waiting-list-lead-container">
            <BackToTopButton />
            <h1 className="text-blue">
                Anfrage: {deutscheSeniorenPortalDetailsInfo?.dspLeadNumber}
            </h1>
            <DeutscheSeniorenPortalLeadAcquired />

            <DeutscheSeniorenPortalLeadClientInformationTable
                firstName={
                    deutscheSeniorenPortalDetailsInfo?.clientInformation
                        ?.firstName
                }
                lastName={
                    deutscheSeniorenPortalDetailsInfo?.clientInformation
                        ?.lastName
                }
                email={
                    deutscheSeniorenPortalDetailsInfo?.clientInformation?.email
                }
                requestRegion={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation?.city
                }
                requestPostalCode={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.postalCode
                }
                houseNumber={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.houseNumber
                }
                street={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation?.street
                }
                phoneNumber={
                    deutscheSeniorenPortalDetailsInfo?.clientInformation
                        ?.phoneNumber
                }
                productName={getProductDisplayName(
                    deutscheSeniorenPortalDetailsInfo?.productId
                )}
            />

            <DeutscheSeniorenPortalLeadSeniorInformationTable
                firstName={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.firstName
                }
                lastName={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.lastName
                }
                dateOfBirth={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.dateOfBirth
                }
                careLevel={getCareLevelDisplayName(
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.careLevel?.level
                )}
                typeOfCare={
                    deutscheSeniorenPortalDetailsInfo?.seniorInformation
                        ?.typeOfCare
                }
            />

            <DeutscheSeniorenPortalLeadProductCriteriaTable
                productCriteriaList={
                    deutscheSeniorenPortalDetailsInfo?.productCriteriaList
                }
            />
            {deutscheSeniorenPortalDetailsInfo?.partnerInformation && (
                <DeutscheSeniorenPortalLeadPartnerInformationTable
                    firstName={
                        deutscheSeniorenPortalDetailsInfo?.partnerInformation
                            ?.firstName
                    }
                    lastName={
                        deutscheSeniorenPortalDetailsInfo?.partnerInformation
                            ?.lastName
                    }
                    dateOfBirth={
                        deutscheSeniorenPortalDetailsInfo?.partnerInformation
                            ?.dateOfBirth?.split("T")[0]
                    }
                    careLevel={getCareLevelDisplayName(
                        deutscheSeniorenPortalDetailsInfo?.partnerInformation
                            ?.careLevel?.level
                    )}
                    typeOfCare={
                        deutscheSeniorenPortalDetailsInfo?.partnerInformation
                            ?.typeOfCare
                    }
                />
            )}
        </div>
    );
};

export default DeutscheSeniorenPortalLeadOverview;
