import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Collapse } from "@mui/material";
import React from "react";

export interface IMenuItem {
    label: string;
    path?: string;
    disabled?: boolean;
}

export interface IManagementNavbarProps {
    label: string;
    menuItems: IMenuItem[];
    path: string;
}

function ManagementNavbar({
    label,
    menuItems,
    path
}: Readonly<IManagementNavbarProps>) {
    const [isOpened, setIsOpened] = useState(true);
    const location = useLocation();

    const isSubMenuActiveLink = () => {
        const paths = menuItems
            .map((item) => item.path)
            .filter(Boolean) as string[];
        return paths.some((path) => location.pathname.includes(path));
    };

    const isMenuActiveLink = () => {
        return location.pathname.includes(path);
    };

    const hasMenuItems = menuItems.length > 0;

    return (
        <div className="aside-menu">
            {hasMenuItems ? (
                <>
                    <h6
                        className={`aside-label ${
                            !isSubMenuActiveLink() ? "pointer" : ""
                        }`}
                        onClick={() => setIsOpened((prev) => !prev)}
                    >
                        <span
                            className={
                                isOpened || isSubMenuActiveLink()
                                    ? "icons-down-open"
                                    : "icons-right-open"
                            }
                        />
                        {label}
                    </h6>

                    <Collapse in={isOpened || isSubMenuActiveLink()}>
                        <div className="sub-menu">
                            {menuItems.map((item, index) =>
                                item.disabled ? (
                                    <div
                                        key={index}
                                        className="navigation-link col-12 disabled"
                                    >
                                        <span>{item.label}&nbsp;</span>
                                        <span className="icons-lock"></span>
                                    </div>
                                ) : (
                                    <NavLink
                                        key={index}
                                        to={item.path!}
                                        className={({ isActive }) => {
                                            const isExactActive =
                                                isActive &&
                                                location.pathname === item.path; // Ensure exact path match
                                            return `navigation-link col-12 ${
                                                isExactActive ? "active" : ""
                                            }`;
                                        }}
                                        end
                                    >
                                        <span>{item.label}</span>
                                    </NavLink>
                                )
                            )}
                        </div>
                    </Collapse>
                </>
            ) : (
                <NavLink to={path} className="no-child-navigation-link ">
                    <h6
                        className={`aside-label ${
                            isMenuActiveLink() ? "active" : ""
                        }`}
                    >
                        {label}
                    </h6>
                </NavLink>
            )}
        </div>
    );
}

export default ManagementNavbar;
