/**
 * Enum representing various types of care services, medical equipment, financial products, and property-related services.
 */
export enum Product {
    StationaryCare = 1, // Vollstationäre Pflege
    AssistedLivingCare = 3, // Betreutes Wohnen
    ShortTermCare = 4, // Kurzzeitpflege
    DayCare = 5, // Tagespflege
    InPatientCareIntensiveCare = 28, // Stationäre Intensivpflege
    RehabilitationClinic = 48, // Reha Klinik
    OutPatientCareIntensiveCare = 6, // 1:1 – Intensivpflege
    IntensiveCareSharedRoom = 47, // Intensivpflege-WG
    NursingService = 7, // Pflegedienst
    _24HoursCare = 8, // 24 Stunden Betreuung
    _24HoursShortTermCare = 23, // 24h Verhinderungspflege
    German24HoursCare = 31, // Deutsche 24h Betreuung
    HourlyElderCare = 9, // Stundenweise Betreuung
    HomeHelp = 10, // Haushaltshilfe
    EmergencyCallSystem = 11, // Hausnotruf
    MealsOnWheels = 12, // Essen auf Rädern
    SeniorsSharedRoom = 30, // Senioren-WG
    OnSiteCareAdvice = 32, // Pflegeberatung vor Ort
    CareProducts = 13, // Pflegehilfsmittel
    StepChairLift = 14, // Sitzlift
    HubLift = 15, // Hublift
    WheelChairLift = 16, // Rollstuhllift
    VerticalLift = 17, // Senkrechtlift
    Elevator = 46, // Aufzug
    PatientLift = 34, // Patientenlifter
    ReconstructionBathToShower = 18, // Badewanne zur Dusche
    SeatBathTub = 24, // Seniorenbadewanne
    BathTubDoor = 25, // Badewannentür
    BathRennovation = 26, // Badsanierung
    BathroomExhibition = 29, // Badausstellung
    CareInsurance = 20, // Pflegevorsorge
    ElectricVehicle = 21, // Elektromobil
    ElectricWheelChair = 37, // Elektrorollstuhl
    BathLift = 22, // Badewannenlift
    WheelChairRamps = 35, // Rollstuhlrampen
    CareArmchair = 36, // Pflegesessel
    SeniorsTablet = 38, // Senioren-Tablet
    Diet = 42, // Ernährung
    NursingBed = 45, // Pflegebett
    Incontinence = 49, // Inkontinenzartikel
    HearingAid = 51, // Hörgerät
    HearingTest = 60, // Hörtest
    QualifiedNurse = 39, // Pflegefachkraft
    NursingAssistant = 40, // Pflegehelfer
    NurseryManager = 50, // Pflegedienstleitung
    CareTrainees = 52, // Pflegeazubis
    CareGiver = 57, // Betreuungskraft
    CareProperty = 19, // Pflegeimmobilien
    PropertiesRetirement = 33, // Immobilienverrentung
    HouseSale = 41, // Immobilienverkauf
    ListedPropertySale = 53, // Denkmalimmobilien
    BarrierFreeLiving = 54, // Barrierefreie Wohnungen
    CapitalInvestment = 55, // Kapitalanlage
    RetirementProvision = 56, // Altersvorsorge
    Hospital = 58, // Krankenhaus
    CareCoursesForRelatives = 59, // Pflegekurse für Angehörige
    JobApplication = 1000, // Bewerbung
    OutPatient = 1001, //Ambulante Pflege,
    InPatientCare = 1002 // Stationäre Pflege
}

/**
 * Retrieves the display name of a given product.
 * @param product The product to retrieve the display name for.
 * @returns The display name of the product.
 */
export function getProductDisplayName(product: Product | undefined): string {
    switch (product) {
        case Product.StationaryCare:
            return "Vollstationäre Pflege";
        case Product.AssistedLivingCare:
            return "Betreutes Wohnen";
        case Product.ShortTermCare:
            return "Kurzzeitpflege";
        case Product.DayCare:
            return "Tagespflege";
        case Product.InPatientCareIntensiveCare:
            return "Stationäre Intensivpflege";
        case Product.RehabilitationClinic:
            return "Reha Klinik";
        case Product.OutPatientCareIntensiveCare:
            return "1:1 -Intensivpflege";
        case Product.IntensiveCareSharedRoom:
            return "Intensivpflege-WG";
        case Product.NursingService:
            return "Pflegedienst";
        case Product._24HoursCare:
            return "24 Stunden Betreuung";
        case Product._24HoursShortTermCare:
            return "24h Verhinderungspflege";
        case Product.German24HoursCare:
            return "Deutsche 24h Betreuung";
        case Product.HourlyElderCare:
            return "Stundenweise Betreuung";
        case Product.HomeHelp:
            return "Haushaltshilfe";
        case Product.EmergencyCallSystem:
            return "Hausnotruf";
        case Product.MealsOnWheels:
            return "Essen auf Rädern";
        case Product.SeniorsSharedRoom:
            return "Senioren-WG";
        case Product.OnSiteCareAdvice:
            return "Pflegeberatung vor Ort";
        case Product.CareProducts:
            return "Pflegehilfsmittel";
        case Product.StepChairLift:
            return "Sitzlift";
        case Product.HubLift:
            return "Hublift";
        case Product.WheelChairLift:
            return "Rollstuhllift";
        case Product.VerticalLift:
            return "Senkrechtlift";
        case Product.Elevator:
            return "Aufzug";
        case Product.PatientLift:
            return "Patientenlifter";
        case Product.ReconstructionBathToShower:
            return "Badewanne zur Dusche";
        case Product.SeatBathTub:
            return "Seniorenbadewanne";
        case Product.BathTubDoor:
            return "Badewannentür";
        case Product.BathRennovation:
            return "Badsanierung";
        case Product.BathroomExhibition:
            return "Badausstellung";
        case Product.CareInsurance:
            return "Pflegevorsorge";
        case Product.ElectricVehicle:
            return "Elektromobil";
        case Product.ElectricWheelChair:
            return "Elektrorollstuhl";
        case Product.BathLift:
            return "Badewannenlift";
        case Product.WheelChairRamps:
            return "Rollstuhlrampen";
        case Product.CareArmchair:
            return "Pflegesessel";
        case Product.SeniorsTablet:
            return "Senioren-Tablet";
        case Product.Diet:
            return "Ernährung";
        case Product.NursingBed:
            return "Pflegebett";
        case Product.Incontinence:
            return "Inkontinenzartikel";
        case Product.HearingAid:
            return "Hörgerät";
        case Product.HearingTest:
            return "Hörtest";
        case Product.QualifiedNurse:
            return "Pflegefachkraft";
        case Product.NursingAssistant:
            return "Pflegehelfer";
        case Product.NurseryManager:
            return "Pflegedienstleitung";
        case Product.CareTrainees:
            return "Pflegeazubis";
        case Product.CareGiver:
            return "Betreuungskraft";
        case Product.CareProperty:
            return "Pflegeimmobilien";
        case Product.PropertiesRetirement:
            return "Immobilienverrentung";
        case Product.HouseSale:
            return "Immobilienverkauf";
        case Product.ListedPropertySale:
            return "Denkmalimmobilien";
        case Product.BarrierFreeLiving:
            return "Barrierefreie Wohnungen";
        case Product.CapitalInvestment:
            return "Kapitalanlage";
        case Product.RetirementProvision:
            return "Altersvorsorge";
        case Product.Hospital:
            return "Krankenhaus";
        case Product.CareCoursesForRelatives:
            return "Pflegekurse für Angehörige";
        case Product.JobApplication:
            return "Bewerbung";
        case Product.OutPatient:
            return "Ambulante Pflege";
        case Product.InPatientCare:
            return "Stationäre Pflege";
        default:
            return "Unbekanntes Produkt";
    }
}
