export const CareLevel = {
    NotApplied: "noch nicht beantragt",
    Applied: "beantragt",
    Level0: "Level 0",
    Level1: "Level 1",
    Level2: "Level 2",
    Level3: "Level 3",
    Level4: "Level 4",
    Level5: "Level 5",
    unknown: "unknown"
} as const;

// Static mapping for non-numeric care levels
const StaticCareLevelDisplayNameMap: Record<string, string> = {
    [CareLevel.NotApplied]: "Entlass-Manager",
    [CareLevel.Applied]: "Verbund Pflegehilfe",
    [CareLevel.unknown]: "noch kein Befund"
};

// Dynamically generate numeric level mappings
const NumericCareLevelDisplayNameMap = Array.from({ length: 6 }, (_, i) => ({
    [`Level ${i}`]: `Pflegegrad ${i}`,
    [`${i}`]: `Pflegegrad ${i}`
})).reduce((map, entry) => ({ ...map, ...entry }), {});

// Combine static and numeric mappings
const CareLevelDisplayNameMap = {
    ...StaticCareLevelDisplayNameMap,
    ...NumericCareLevelDisplayNameMap
};

/**
 * Function to get the string representation of a CareLevel.
 * @param careLevel - The CareLevel enum value or numeric string.
 * @returns The string representation of the CareLevel.
 */
export function getCareLevelDisplayName(careLevel: string | undefined): string {
    return CareLevelDisplayNameMap[careLevel || ""] || "Unknown";
}
