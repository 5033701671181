/**
 * @function WohnenImAlterLeadAcquired
 * @description Component to display the acquired lead status.
 * @returns {JSX.Element} - The JSX element for the acquired lead status.
 */

import { Link } from "react-router-dom";

function WohnenImAlterLeadAcquired() {
    return (
        <div className="waiting-list-lead-acquired-container">
            <div className="flex-nowrap align-center">
                <div className="icon icon-email"></div>
                <h3 className="highlight-green">
                    Sie haben eine neue Anfrage erhalten.
                </h3>
            </div>

            <div className="text-black mtb-30">
                Sie sind nicht der korrekte Ansprechpartner? &nbsp;
                <Link to={`/contact`} className="link">
                    Kontakdaten aktualisieren{" "}
                    <i
                        className="icon icon-external-link"
                        aria-hidden="true"
                    ></i>
                </Link>
            </div>
        </div>
    );
}

export default WohnenImAlterLeadAcquired;
