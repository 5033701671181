import { Box, Button, FormControl, TextField } from "@mui/material";
import { IAreaInformation } from "../types";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useGetZipCodesByStartsWithAndEndsWith } from "../services/zipCodeService";
import ZipCodeSearchResultDetails from "./ZipCodeSearchResultDetails";
import ZipCodeModalActions from "./ZipCodeModalActions";
import { generateUUIDFromTimestamp } from "utils";
import { GermanCountryIso2 } from "../../../constants";

interface Props {
    handleZipCodeGroupAdd: (areaInformation: IAreaInformation) => void;
    handleModalCloseEvent: () => void;
}

interface FormData {
    startRange: string;
    endRange: string;
}

/**
 * ZipCodeByStartWithAndEndWith is a React component that allows the user to search for a group of zip codes
 * within a range. The callback function handleZipCodeGroupAdd
 * is called whenever the user adds an to the group to update the list of postal codes and potential number.
 */
function ZipCodeByStartWithAndEndWith({
    handleZipCodeGroupAdd,
    handleModalCloseEvent
}: Readonly<Props>) {
    const [zipCodeRange, setZipCodeRange] = useState({
        start: "",
        end: ""
    });
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>();
    const { data: searchInfo } = useGetZipCodesByStartsWithAndEndsWith(
        zipCodeRange.start,
        zipCodeRange.end,
        GermanCountryIso2,
        {
            enabled:
                zipCodeRange.start.length > 0 &&
                zipCodeRange.end.length > 0 &&
                isSearchTriggered
        }
    );

    function zipCodeGroupAddClick() {
        if (searchInfo === undefined || searchInfo.length === 0) return;
        handleZipCodeGroupAdd({
            areaId: generateUUIDFromTimestamp(),
            name: `PLZ ${zipCodeRange.start} - ${zipCodeRange.end}`,
            totalNumberOfZipCode: searchInfo.length,
            zipCodeList: searchInfo.map((item) => item.zipCode)
        });
    }

    function onZipCodeRangeInputChange() {
        setIsSearchTriggered(false);
    }

    function onSubmit(formData: FormData) {
        setZipCodeRange({
            start: formData.startRange,
            end: formData.endRange
        });
        setIsSearchTriggered(true);
    }

    function renderSearchResults() {
        if (searchInfo === undefined || !isSearchTriggered) {
            return null;
        } else if (searchInfo.length === 0) {
            return (
                <div className="error-message">
                    {`Keine Postleitzahlen für ${zipCodeRange.start} - ${zipCodeRange.end} gefunden.`}
                </div>
            );
        } else {
            return (
                <ZipCodeSearchResultDetails
                    zipCodeList={searchInfo.map((item) => item.zipCode)}
                />
            );
        }
    }

    return (
        <div>
            <div className="highlight-blue">Details hinterlegen:</div>
            <p className="zipcode-input-heading">Postletzahlen</p>
            <Box
                className="zipcode-input"
                component="form"
                onSubmit={handleSubmit(onSubmit)}
            >
                <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                    <TextField
                        label="Postleitzahl angeben"
                        variant="outlined"
                        error={Boolean(errors.startRange)}
                        {...register("startRange", {
                            onChange: () => {
                                onZipCodeRangeInputChange();
                            },
                            required: true
                        })}
                    />
                </FormControl>
                <div className="divider">-</div>
                <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                    <TextField
                        label="Postleitzahl angeben"
                        variant="outlined"
                        error={Boolean(errors.endRange)}
                        {...register("endRange", {
                            onChange: () => {
                                onZipCodeRangeInputChange();
                            },
                            required: true
                        })}
                    />
                </FormControl>
                <Button type="submit" variant="outlined">
                    Suchen
                </Button>
            </Box>
            {(errors.startRange || errors.endRange) && (
                <div className="error-message">
                    Bitte geben Sie eine gültige Postleitzahl ein.
                </div>
            )}
            {renderSearchResults()}
            <ZipCodeModalActions
                disabled={!(searchInfo?.length && isSearchTriggered)}
                zipCodeGroupAddClick={zipCodeGroupAddClick}
                modalCloseButtonClick={handleModalCloseEvent}
            />
        </div>
    );
}

export default ZipCodeByStartWithAndEndWith;
