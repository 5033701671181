import GenericInformationTable, { TableRow } from "lib/table/GenericInformationTable";

interface ClientInformationInfoProps {
    firstName: string | undefined;
    lastName: string | undefined;
    productName: string | undefined;
    email: string | undefined;
    requestRegion: string | undefined;
    requestPostalCode: string | undefined;
    houseNumber: string | undefined;
    street : string | undefined;
    phoneNumber: string | undefined;
}

/**
 * DeutscheSeniorenPortalLeadClientInfomationTable renders key lead information using the GenericInformationTable component.
 */
export function DeutscheSeniorenPortalLeadClientInformationTable({
    firstName,
    lastName,
    productName,
    email,
    requestRegion,
    requestPostalCode,
    houseNumber,
    street,
    phoneNumber
}: Readonly<ClientInformationInfoProps>) {
    const rows: TableRow[] = [
        { fieldName: "Name, Vorname:", value: firstName && lastName ? `${lastName}, ${firstName}` : firstName || lastName },
        { fieldName: "Telefonnummer:", value: phoneNumber },
        { fieldName: "Bedarf:", value: productName },
        { fieldName: "Bedarfsort:", value: `${street || ""}, ${houseNumber || ""}, ${requestRegion || ""}, ${requestPostalCode || ""}`.trim() },
        { fieldName: "Email:", value: email }
    ];

    return <GenericInformationTable title="Die wichtigsten Daten auf einen Blick" rows={rows} />;
}