import { Modal } from "@mui/material";
import "../../../assets/styles/preLaunchRegistrationPromotion/preLaunchRegistrationPromotion.scss"

interface ILeadReceiptConfirmationModalProps {
    popUpOpen: boolean;
    setPopUpOpen: (open: boolean) => void;
}

/**
 * Confirmation popup after registration
 */

function PreLaunchRegistrationConfirmationModal({
    popUpOpen,
    setPopUpOpen
}: Readonly<ILeadReceiptConfirmationModalProps>) {
    return (
        <Modal
            className="modal"
            open={popUpOpen}
            onClose={() => {
                setPopUpOpen(false);
            }}
            aria-labelledby="pre-registration-for-anfragen-manager-success-message-label"
            aria-describedby="pre-registration-for-anfragen-manager-success-message-description"
        >
            <div className="modal-body">
                <div
                    className="icon icon-close"
                    role="button"
                    onClick={() => setPopUpOpen(false)}
                ></div>
                <h2 className="highlight-blue">
                    Vielen Dank für Ihr Interesse am Anfragen-Manager!
                </h2>
                <p className="text-black mt-20">
                    <strong>So geht es jetzt weiter:</strong>
                </p>
                <ol className="list">
                    <li>
                        Wir werden Sie innerhalb von 48 Stunden unter der
                        angegebenen Telefonnummer kontaktieren.
                    </li>
                    <li>
                        Wir vereinbaren einen Termin für eine kostenlose Live
                        Demonstration des Anfragen-Managers.
                    </li>
                    <li>
                        Sie erhalten exklusiven Zugang zu einem kostenlosen
                        Test-Monat für den Anfragen-Manager.
                    </li>
                </ol>
            </div>
        </Modal>
    );
}
export default PreLaunchRegistrationConfirmationModal;
