import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    BodyContainer,
    Header,
    MobileNavigationDrawer,
    SideNavbar
} from "../../../components/ui";
import HomePage from "../../../components/ui/HomePage";
import ManagementNavbar, {
    IManagementNavbarProps
} from "components/ui/ManagementNavbar";
import { useNavigationDrawer } from "../../../context";
import {
    clearTokenCookies,
    SupplierName,
    UnAuthorizedErrorMessage,
    useLoginWithMagicToken,
    useLoginWithRefreshToken
} from "../../../lib/auth/authService";
import { getDataByCookie } from "../../../utils/cookie";
import { LoginResponseType } from "../../../types/authTypes";
import {
    useGetAuthenticateSupplierInfo,
    useGetSupplierInfoBySecuredId
} from "../services/supplierInfoService";
import { SupplierIdContext } from "../context/SupplierIdContext";
import { SupplierContext } from "../context/SupplierContext";
import { SupplierAuthenticateContext } from "../context/SupplierAuthenticateContext";
import ApiError from "../../../lib/ApiError";

/**
 * Layout component for the Anfragen Manager CRM Application.
 * This component handles authentication using a token passed as a query parameter.
 * It displays navigation and content areas only if the user is successfully authenticated.
 */
export function AnfragenManagerCRMSupplierContainer() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token") ?? "";
    const { secureId } = useParams() as { secureId: string };
    const [isAuthenticated, setIsAuthenticated] = useState<
        boolean | undefined
    >();
    const [
        fetchUnauthenticatedSupplierInfo,
        setFetchUnauthenticatedSupplierInfo
    ] = useState(false);
    const navigate = useNavigate();
    const [fetchAuthenticatedSupplierInfo, setFetchAuthenticatedSupplierInfo] =
        useState(false);
    const [supplierName, setSupplierName] = useState<string>("");
    const [supplierId, setSupplierId] = useState<string>("");
    const { isNavigationDrawerOpen } = useNavigationDrawer();

    const [currentNavbarSections, setNavbarSections] = useState<
        IManagementNavbarProps[]
    >([
        {
            label: "Klienten-Verwaltung",
            menuItems: [
                {
                    label: "Klientenübersicht",
                    path: "/LeadOverview",
                    disabled: false
                },
                { label: "Neuer Klient", path: "/new-client", disabled: true }
            ],
            path: ""
        },
        {
            label: "Bewerber-Verwaltung",
            menuItems: [
                {
                    label: "Bewerber",
                    path: "/",
                    disabled: true
                },
                {
                    label: "Neuer Bewerber",
                    path: "/",
                    disabled: true
                }
            ],
            path: "/"
        },
        {
            label: "Kontaktdaten",
            menuItems: [],
            path: "/contact"
        },
        {
            label: "Zielgebiet",
            menuItems: [],
            path: "/zip-code"
        }
    ]);

    const { mutate: loginWithMagicToken } = useLoginWithMagicToken({
        onSuccess: (data: LoginResponseType) => {
            if (data === UnAuthorizedErrorMessage) {
                loginWithRefreshTokenToken();
            } else {
                setIsAuthenticated(true);
            }
        },
        onError: (error: any) => {
            if (error == "Unauthorized access") {
                loginWithRefreshTokenToken();
            } else {
                navigate("/404", { replace: true });
            }
        }
    });

    const { mutate: loginWithRefreshTokenToken } = useLoginWithRefreshToken({
        onSuccess: (data: LoginResponseType) => {
            setIsAuthenticated(data !== UnAuthorizedErrorMessage);
        },
        onError: () => {
            setIsAuthenticated(false);
        }
    });

    const {
        data: unauthenticatedSupplierInfo,
        isSuccess: isUnauthenticatedSupplierInfoSuccess,
        isError: unauthenticatedSupplierInfoError,
        error: unauthenticatedSupplierInfoOnError
    } = useGetSupplierInfoBySecuredId(secureId, {
        enabled: fetchUnauthenticatedSupplierInfo
    });

    const {
        data: authenticatedSupplierInfo,
        isSuccess: isAuthenticatedSupplierInfoSuccess
    } = useGetAuthenticateSupplierInfo({
        enabled: fetchAuthenticatedSupplierInfo
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // useEffect hook to perform side effects. In this case, it checks if an API call resulted in an error.
        // If there is an error, specifically a 404 error, it navigates the user to a 404 page.
        // The dependency array includes isSuccess and isError to only rerun this effect when these values change.
        if (unauthenticatedSupplierInfoError) {
            if (
                unauthenticatedSupplierInfoOnError instanceof ApiError &&
                unauthenticatedSupplierInfoOnError.status === 404
            ) {
                navigate("/404", { replace: true });
            }
        }
    }, [
        isUnauthenticatedSupplierInfoSuccess,
        unauthenticatedSupplierInfoError
    ]);

    function isFreeFeature(): boolean {
        if (typeof window !== "undefined" && window.location?.pathname) {
            if (
                window.location.pathname.includes(
                    "waiting-list-lead-details"
                ) ||
                window.location.pathname.includes("contact") ||
                window.location.pathname.includes("zip-code")
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }

    function updateNavbarSectionsForDetailsPage() {
        const currentRoute = location.pathname;

        // Extract the base path from the current route (up to the unique identifier).
        const basePathMatch = currentRoute.match(
            /(.*\/waiting-list-lead-details\/[^/]+)/
        );
        const basePath = basePathMatch ? basePathMatch[1] : "";

        const updatedNavbarSections = currentNavbarSections.map((section) => {
            // Update paths for "Kontaktdaten" and "Zielgebiet".
            if (section.label === "Kontaktdaten") {
                return { ...section, path: `${basePath}/contact` };
            }
            if (section.label === "Zielgebiet") {
                return { ...section, path: `${basePath}/zip-code` };
            }

            return section;
        });

        if (isFreeFeature() && isAuthenticated == false) {
            updatedNavbarSections[0].menuItems[0].path = currentRoute;
        }

        setNavbarSections(updatedNavbarSections);
    }

    useEffect(() => {
        loginWithMagicToken(token);
    }, [loginWithMagicToken, token]);

    useEffect(() => {
        if (isAuthenticated === false && isFreeFeature()) {
            setFetchUnauthenticatedSupplierInfo(true);
            if (
                unauthenticatedSupplierInfo &&
                isUnauthenticatedSupplierInfoSuccess
            ) {
                setSupplierName(unauthenticatedSupplierInfo?.supplierName);
                setSupplierId(unauthenticatedSupplierInfo?.supplierId);
            }
        } else if (isAuthenticated === true) {
            setFetchAuthenticatedSupplierInfo(true);
            if (
                authenticatedSupplierInfo &&
                isAuthenticatedSupplierInfoSuccess
            ) {
                setSupplierId(authenticatedSupplierInfo?.supplierId);
            }
            setSupplierName(getDataByCookie(SupplierName));
        }
        updateNavbarSectionsForDetailsPage();
    }, [
        isAuthenticated,
        isAuthenticatedSupplierInfoSuccess,
        isUnauthenticatedSupplierInfoSuccess,
        secureId
    ]);

    if (isAuthenticated === false && !isFreeFeature()) {
        clearTokenCookies();
        return <HomePage />;
    }

    return (
        <>
            {isAuthenticated !== undefined && supplierId !== "" && (
                <SupplierAuthenticateContext.Provider value={isAuthenticated}>
                    <SupplierIdContext.Provider value={supplierId}>
                        <SupplierContext.Provider value={supplierName}>
                            <Header
                                isPartnered={false}
                                showNavigationDrawerIcon={true}
                            />
                            <BodyContainer hideOutlet={isNavigationDrawerOpen}>
                                <SideNavbar
                                    className="hidden-small-screen"
                                    name={supplierName}
                                >
                                    <div className="col-12 separator" />
                                    {currentNavbarSections.map(
                                        (section, index) => (
                                            <React.Fragment key={index}>
                                                <ManagementNavbar
                                                    label={section.label}
                                                    path={section.path}
                                                    menuItems={
                                                        section.menuItems
                                                    }
                                                />
                                                <div className="col-12 separator" />
                                            </React.Fragment>
                                        )
                                    )}
                                </SideNavbar>

                                <MobileNavigationDrawer
                                    hidden={!isNavigationDrawerOpen}
                                    className="show-small-screen"
                                >
                                    {currentNavbarSections.map(
                                        (section, index) => (
                                            <React.Fragment key={index}>
                                                <ManagementNavbar
                                                    label={section.label}
                                                    path={section.path}
                                                    menuItems={
                                                        section.menuItems
                                                    }
                                                />
                                                <div className="col-12 separator" />
                                            </React.Fragment>
                                        )
                                    )}
                                </MobileNavigationDrawer>
                            </BodyContainer>
                        </SupplierContext.Provider>
                    </SupplierIdContext.Provider>
                </SupplierAuthenticateContext.Provider>
            )}
        </>
    );
}
