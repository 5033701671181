import { Route } from "react-router-dom";
import { AnfragenManagerCRMSupplierContainer } from "../../features/supplierInfo/components/AnfragenManagerCRMSupplierContainer";
import WaitingListLeadOverviewV1 from "../../features/WaitingListLeadV1/components/WaitingListLeadOverviewV1";
import {
    LeadReceiptsContactInformationOverview,
    ZipCodeInformationOverview
} from "../../features/waitingListBookingChangeRequest";
import React from "react";
import { WohnenImAlterLeadOverview } from "features/external-lead-providers/wohnenImAlter";
import { DeutscheSeniorenPortalLeadOverview } from "features/external-lead-providers/deutscheSeniorenPortal";
import { LeadOverview } from "features/leadOverview";

const AnfragenManagerCRMRoutes = (
    <>
        <Route path="/" element={<AnfragenManagerCRMSupplierContainer />}>
            <Route path="/leadOverview" element={<LeadOverview />} />
            <Route
                path="/waiting-list-lead-details/:secureId"
                element={<WaitingListLeadOverviewV1 />}
            ></Route>
            <Route
                path="/waiting-list-lead-details/:secureId/contact"
                element={<LeadReceiptsContactInformationOverview />}
            ></Route>
            <Route
                path="/waiting-list-lead-details/:secureId/zip-code"
                element={<ZipCodeInformationOverview />}
            ></Route>
            <Route
                path="/contact"
                element={<LeadReceiptsContactInformationOverview />}
            ></Route>
            <Route
                path="/zip-code"
                element={<ZipCodeInformationOverview />}
            ></Route>
            <Route
                path="/deutsche-senioren-portal-lead-details/:secureId"
                element={<DeutscheSeniorenPortalLeadOverview />}
            />
            <Route
                path="/wohnen-im-alter-lead-details/:secureId"
                element={<WohnenImAlterLeadOverview />}
            />
        </Route>
    </>
);

export default AnfragenManagerCRMRoutes;
