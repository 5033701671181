import { useNavigate, useParams } from "react-router-dom";
import { useGetWaitingListLeadBasicInfo } from "../services/waitingListLeadService";
import "../../../assets/styles/waitingListLead/WaitingListLeadOverview.scss";
import WaitingListLeadStateOpen from "./WaitingListLeadStateOpen";
import WaitingListLeadStateClosed from "./WaitingListLeadStateClosed";
import { WaitingListLeadState } from "../types/enums";
import WaitingListLeadStatusAcquired from "./WaitingListLeadStateAcquired";
import WaitingListLeadStateRejected from "./WaitingListLeadStateRejected";
import WaitingListLeadBasicInformationTable from "./WaitingListLeadBasicInformationTable";
import { getCommaSeparatedPhoneNumberFromArray } from "../utils/waitingListLeadOverview";
import WaitingListLeadProductCriteriaInformationTable from "./WaitingListLeadProductCriteriaInformationTable";
import ApiError from "../../../lib/ApiError";
import { useEffect, useState } from "react";
import BackToTopButton from "../../../components/ui/BackToTopButton";
import WaitingListLeadStateAcquiredWithClosedDate from "./WaitingListLeadStateAcquiredWithClosedDate";
import {PreLaunchRegistrationPromotion } from "../../PreLaunchRegistration";


/**
 *
 * WaitingListLeadOverview is a React component that serves as the main interface for displaying detailed information about a specific waiting list lead.
 * It fetches the lead's basic information based on a secure ID obtained from the URL parameters and manages API errors,
 * such as redirecting to a 404 page if the lead is not found.
 *
 */
function WaitingListLeadOverview() {
    const { secureId } = useParams() as { secureId: string };
    const navigate = useNavigate();
    const {
        data: waitingListLeadBasicInfo,
        isSuccess,
        isError,
        error
    } = useGetWaitingListLeadBasicInfo(secureId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // useEffect hook to perform side effects. In this case, it checks if an API call resulted in an error.
        // If there is an error, specifically a 404 error, it navigates the user to a 404 page.
        // The dependency array includes isSuccess and isError to only rerun this effect when these values change.
        if (isError) {
            if (error instanceof ApiError && error.status === 404) {
                navigate("/404", { replace: true });
            }
        }
        // Check if isRegisterForAM is in localStorage
        const isSet = localStorage.getItem('isRegisterForAM') !== null;
        setIsRegisterForAMSet(isSet);

    }, [isSuccess, isError]);
    
    const [isRegisterForAMSet, setIsRegisterForAMSet] = useState(false);
    const [preLaunchRegistrationPromotionModalOpen, setPreLaunchRegistrationPromotionModal] = useState<boolean>(false);

    // Defines a function to render different components based on the waiting list lead's state.
    // This function takes a state parameter and returns a JSX component according to the provided state.
    function getWaitingListLeadStatusView(
        state: WaitingListLeadState,
        isClosedByClient: boolean,
        isPersonalProduct:boolean
    ) {
        
        if (isClosedByClient) {
            if (state === WaitingListLeadState.Acquired) {
                return <WaitingListLeadStateAcquiredWithClosedDate isPersonalProduct={isPersonalProduct}/>;
            }
            return <WaitingListLeadStateClosed isPersonalProduct={isPersonalProduct}/>;
        }
        switch (state) {
            case WaitingListLeadState.Open:
                return <WaitingListLeadStateOpen 
                    secureId={secureId} isPersonalProduct={isPersonalProduct} 
                    onSuccessCallback={() => setTimeout(() => showPreLaunchRegistrationPromotionModal(), 2000)}
                />;
            case WaitingListLeadState.Acquired:
                return <WaitingListLeadStatusAcquired isPersonalProduct={isPersonalProduct}/>;
            default:
                return <WaitingListLeadStateRejected/>;
        }
    }

    function showPreLaunchRegistrationPromotionModal(){
        if(!isRegisterForAMSet){
             setPreLaunchRegistrationPromotionModal(true);
        }
    }

    function onSuccess(){
        setIsRegisterForAMSet(true);
        localStorage.setItem('isRegisterForAM', 'true');
    }

    function onError(){
        localStorage.setItem('isRegisterForAM', 'false');
        setIsRegisterForAMSet(false);
    }

    return (
        <div className="waiting-list-lead-container">

            <PreLaunchRegistrationPromotion               
                preLaunchRegistrationPromotionModalOpen = {preLaunchRegistrationPromotionModalOpen}
                setPreLaunchRegistrationPromotionModal ={setPreLaunchRegistrationPromotionModal}
                onEmailSend={() =>  onSuccess()}
                onEmailError={() =>  onError()}
              />

            <BackToTopButton />
            
            {
                (   waitingListLeadBasicInfo?.waitingListLeadState == WaitingListLeadState.Rejected || 
                    waitingListLeadBasicInfo?.waitingListLeadState == WaitingListLeadState.Acquired) && 
                    (
                        <div className="waiting-list-lead-nav-box">
                            {
                                !isRegisterForAMSet && (
                                    <a className="link-pointer pull-right" onClick={() => showPreLaunchRegistrationPromotionModal()}>
                                        Weitere Anfragen erhalten
                                    </a>
                                )
                            }
                        </div>
                    )
            }

            <h1 className="text-blue">
                Anfrage: {waitingListLeadBasicInfo?.waitingListLeadNumber}
            </h1>

            {waitingListLeadBasicInfo &&
                getWaitingListLeadStatusView(
                    waitingListLeadBasicInfo.waitingListLeadState,
                    waitingListLeadBasicInfo.isClosedByClient,
                    waitingListLeadBasicInfo.isPersonalProduct
                )}
            {waitingListLeadBasicInfo && (
                <WaitingListLeadBasicInformationTable
                    firstName={waitingListLeadBasicInfo.firstName}
                    lastName={waitingListLeadBasicInfo.lastName}
                    productName={waitingListLeadBasicInfo.productName}
                    clientAvailability={
                        waitingListLeadBasicInfo.clientAvailability
                    }
                    waitingListLeadState={
                        waitingListLeadBasicInfo.waitingListLeadState
                    }
                    requestRegion={waitingListLeadBasicInfo.requestRegion}
                    requestZipCode={waitingListLeadBasicInfo.requestZipCode}
                    phoneNumber={getCommaSeparatedPhoneNumberFromArray(
                        waitingListLeadBasicInfo.phoneNumbers
                    )}
                    isClosedByClient={waitingListLeadBasicInfo.isClosedByClient}
                    isPersonalProduct={waitingListLeadBasicInfo.isPersonalProduct}
                />
            )}
            <WaitingListLeadProductCriteriaInformationTable/>
        </div>
    );
}

export default WaitingListLeadOverview;
