import {
    useMutation,
    UseMutationOptions,
    UseMutationResult
} from "@tanstack/react-query";
import { ISupplierLoginDetails } from "./dtos";
import ApiError from "../ApiError";
import {
    generateAccessTokenByMagicToken,
    generateAccessTokenByRefreshToken
} from "../../constants/reactQueryKey";
import { AnfragenManagerClient, post } from "../httpService";
import {
    getDataByCookie,
    removeCookieData,
    setCookieData
} from "../../utils/cookie";

export const UnAuthorizedErrorMessage = "Unauthorized" as const;
export type UnAuthorizedType = typeof UnAuthorizedErrorMessage;
export type LoginResponseType = ISupplierLoginDetails | UnAuthorizedType;
export const AccessToken = "access-token";
export const RefreshToken = "refresh-token";
export const SupplierName = "supplier-name";
export const Authorization = "Authorization";

// Helper functions for handling token storage and login errors
const setTokenCookies = (response: ISupplierLoginDetails) => {
    if (!AnfragenManagerClient) {
        console.error("AnfragenManagerClient is null or undefined.");
        return;
    }

    setCookieData(AccessToken, response.accessToken);
    setCookieData(RefreshToken, response.refreshToken);
    setCookieData(SupplierName, response.supplierName);
    AnfragenManagerClient.defaults.headers[Authorization] = generateBearerToken(
        response.accessToken
    );
};

// Usage example for removing tokens
export const clearTokenCookies = () => {
    removeCookieData(AccessToken);
    removeCookieData(RefreshToken);
    removeCookieData(SupplierName);
    delete AnfragenManagerClient?.defaults.headers[Authorization];
};

export const generateBearerToken = (accessToken: string) => {
    return `Bearer ${accessToken}`;
};

const handleLoginError = (error: any): UnAuthorizedType => {
    if(error == "Unauthorized access") return UnAuthorizedErrorMessage;
    if (error?.status === 401) return UnAuthorizedErrorMessage;
    throw new Error(error?.message || "Failed to refresh token");
};

export function getHeaderWithBearerToken() {
    return {
        Authorization: generateBearerToken(getDataByCookie(AccessToken))
    };
}

// Login mutation that prioritizes cached tokens if available
export const useLoginWithMagicToken = (
    options: UseMutationOptions<LoginResponseType, ApiError, string> = {}
): UseMutationResult<LoginResponseType, ApiError, string> => {
    const refreshToken = getDataByCookie(RefreshToken);
    const accessToken = getDataByCookie(AccessToken);
    const supplierName = getDataByCookie(SupplierName);
    const cachedLoginDetails: ISupplierLoginDetails = {
        accessToken,
        refreshToken,
        supplierName
    };

    return useMutation<LoginResponseType, ApiError, string>({
        mutationKey: [generateAccessTokenByMagicToken],
        mutationFn: async (magicToken: string) => {
            if (!magicToken && !accessToken) {
                return Promise.resolve(UnAuthorizedErrorMessage);
            }
            if (!magicToken && accessToken) {
                return Promise.resolve(cachedLoginDetails);
            }

            // Call the `post` function directly for the API call
            try {
                const response = await post<ISupplierLoginDetails>(
                    "account/GenerateAccessTokenByMagicLink",
                    { magicLinkToken: magicToken },
                    AnfragenManagerClient
                );
                setTokenCookies(response);
                return response;
            } catch (error) {
                handleLoginError(error);
                throw error;
            }
        },
        ...options
    });
};

export const useLoginWithRefreshToken = (
    options: UseMutationOptions<LoginResponseType, ApiError, void> = {}
): UseMutationResult<LoginResponseType, ApiError, void> => {
    return useMutation<LoginResponseType, ApiError, void>({
        mutationKey: [generateAccessTokenByRefreshToken],
        mutationFn: async () => {
            const refreshToken = getDataByCookie(RefreshToken);
            if (refreshToken == "") {
                return Promise.resolve(UnAuthorizedErrorMessage);
            }

            try {
                const response = await post<ISupplierLoginDetails>(
                    "account/GenerateAccessTokenByRefreshToken",
                    { refreshToken },
                    AnfragenManagerClient
                );
                setTokenCookies(response);
                return response;
            } catch (error) {
                handleLoginError(error as ApiError);
                throw error;
            }
        },
        ...options
    });
};

export const loginWithRefreshToken = async (): Promise<ISupplierLoginDetails | "Unauthorized"> => {
    const refreshToken =  getDataByCookie(RefreshToken) || "";
    if (!refreshToken) return "Unauthorized";

    try {
        const response = await post<ISupplierLoginDetails>(
            "account/GenerateAccessTokenByRefreshToken",
            { refreshToken },
            AnfragenManagerClient
        );
        setTokenCookies(response);
        return response;
    } catch (error: any) {
        return handleLoginError(error);
    }
};