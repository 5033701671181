import React, { useState } from "react";
import Box from "@mui/material/Box/Box";
import Paper from "@mui/material/Paper/Paper";
import Typography from "@mui/material/Typography/Typography";
import Modal from "@mui/material/Modal";

export interface TableRow {
    fieldName: string;
    value: string | undefined;
    isExpandable?: boolean;
}

interface Props {
    title: string;
    rows: TableRow[];
}

export function GenericInformationTable({ title, rows }: Readonly<Props>) {
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const handleShowMore = (content: string) => {
        setDialogContent(content);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDialogContent("");
    };

    function formatTextWithLineBreaks(text: string): React.ReactNode {
        return text.split(/\r\n|\n|\r/).map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    }

    const ExpandableContent = ({
        value,
        onShowMore
    }: {
        value: string;
        onShowMore: (content: string) => void;
    }) => (
        <>
            {value.slice(0, 50)}
            <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => onShowMore(value)}
            >
                ...(Mehr anzeigen)
            </span>
        </>
    );

    return (
        <div className="lead-table-container">
            <Paper className="lead-table">
                <Box className={`lead-table-title-box green-box`}>
                    <h3 className="text-white">{title}</h3>
                </Box>
                <div aria-label="basic-information">
                    {rows.reduce((acc: JSX.Element[], row, index) => {
                        if (index % 2 === 0) {
                            acc.push(
                                <div
                                    key={`row-${Math.floor(index / 2)}`}
                                    className="lead-table-row"
                                >
                                    <div className="lead-table-cell">
                                        <Typography>
                                            {rows[index]?.fieldName}
                                        </Typography>
                                        <Typography>
                                            {rows[index]?.isExpandable &&
                                            rows[index]?.value?.length! >
                                                100 ? (
                                                <ExpandableContent
                                                    value={
                                                        rows[index]?.value || ""
                                                    }
                                                    onShowMore={handleShowMore}
                                                />
                                            ) : (
                                                rows[index]?.value || "N/A"
                                            )}
                                        </Typography>
                                    </div>
                                    {rows[index + 1] && (
                                        <div className="lead-table-cell">
                                            <Typography>
                                                {rows[index + 1]?.fieldName}
                                            </Typography>
                                            <Typography>
                                                {rows[index + 1]
                                                    ?.isExpandable &&
                                                rows[index + 1]?.value
                                                    ?.length! > 100 ? (
                                                    <ExpandableContent
                                                        value={
                                                            rows[index + 1]
                                                                ?.value || ""
                                                        }
                                                        onShowMore={
                                                            handleShowMore
                                                        }
                                                    />
                                                ) : (
                                                    rows[index + 1]?.value ||
                                                    "N/A"
                                                )}
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        return acc;
                    }, [])}
                </div>
            </Paper>
            {/* Modal for showing full text */}
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                        maxWidth: "90%",
                        maxHeight: "90%",
                        overflow: "auto"
                    }}
                >
                    <Typography>
                        {formatTextWithLineBreaks(dialogContent)}
                    </Typography>
                    <Box mt={2} textAlign="right">
                        <Typography
                            component="span"
                            style={{
                                color: "blue",
                                cursor: "pointer",
                                textDecoration: "underline"
                            }}
                            onClick={handleClose}
                        >
                            Schließen
                        </Typography>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default GenericInformationTable;
