import GenericInformationTable, { TableRow } from "lib/table/GenericInformationTable";
import { IDspCriteriaNameWithValue } from "../services/dtos";

interface ProductCriteriaInfoProps {
    productCriteriaList : IDspCriteriaNameWithValue[] | undefined;
}
/**
 * DeutscheSeniorenPortalLeadSeniorInformationTable renders key lead information using the GenericInformationTable component.
 */
export function DeutscheSeniorenPortalLeadProductCriteriaTable({
    productCriteriaList
}: Readonly<ProductCriteriaInfoProps>) {

    function getDisplayName(value: string | undefined) {
        if (value?.toUpperCase() === "TRUE") {
            return "Ja";
        }
        if (value?.toUpperCase() === "FALSE") {
            return "Nein";
        }
        return value;
    }

    const rows: TableRow[] = (productCriteriaList ?? []).map((criteria) => {
        return { fieldName: criteria.criteriaName, value: getDisplayName(criteria.value) };
    });

    return <GenericInformationTable title="Produktkriterien" rows={rows} />;
}