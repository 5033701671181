import { GridSortModel } from "@mui/x-data-grid";

interface ICustomColumnHeaderProps {
  title: string;
  field: string;
  sortModel: GridSortModel;
}

const CustomColumnHeader = ({ title, field, sortModel }: ICustomColumnHeaderProps) => {
  // Determine the current sort direction
  const sortItem = sortModel.find((item) => item.field === field);
  const sortDirection = sortItem != undefined ? sortItem.sort : null;

  // Get the appropriate sort icon class
  const sortIconClass = getSortIconClass(field, sortDirection);

  return (
    <span>
      {title} &nbsp;
      <span className={`${sortIconClass} column-sort-icon`}></span>
    </span>
  );
};

const getSortIconClass = (field: string, sortDirection: 'asc' | 'desc' | null | undefined): string => {
    const iconClasses: Record<string, { asc: string; desc: string; default: string }> = {
      soldDate: {
        asc: 'icons-sort-alt-down',
        desc: 'icons-sort-alt-up',
        default: 'icons-sort inactive-sort',
      },
      fullName: {
        asc: 'icons-sort-name-down',
        desc: 'icons-sort-name-up',
        default: 'icons-sort inactive-sort',
      },
      default: {
        asc: '',
        desc: '',
        default: '',
      },
    };

    const icons = iconClasses[field] || iconClasses.default;
    return icons[sortDirection || 'default'];
  };

export default CustomColumnHeader;
