import { Typography } from "@mui/material";
import "../../assets/styles/components/BlurredTypograhy.scss";

interface Props {
    isNumber?: boolean;
    length?: number;
    className?: string;
}

/**
 *
 * BlurredTypograhy is a React component which is responsible for displaying a blurred or obscured string of characters, intended to mask sensitive information.
 * It generates a random string of alphabetic or numeric characters based on the provided properties, and displays this string
 * with a visual style that indicates the content is secured or hidden. The component can be used wherever sensitive information
 * needs to be obfuscated in the user interface to maintain privacy or security.
 *
 */
function BlurredTypograhy({ isNumber, length, className }: Readonly<Props>) {
    return (
        <div className="flex-wrap align-center">
            <Typography className={`blurryText ${className}`}>
                Mustermann Max 
            </Typography>
            <div className="icon icon-lock"></div>
        </div>
    );
}

export default BlurredTypograhy;
