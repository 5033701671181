//layout
const supplierFeature = "supplier";
export const getGetSupplierInfo = `${supplierFeature}-get-getSupplierInfo`;
//booking
const bookingFeature = "booking";
export const getGetBookingPotentialsInfo = `${bookingFeature}-get-getBookingPotentialsInfo`;
//zipcode
const zipCodeFeature = "zipCode";
export const getGetPostCodesByGeoItemId = `${zipCodeFeature}-get-GetPosCodesByGeoItemId`;
export const getGetAreasBySearch = `${zipCodeFeature}-get-GetAreasBySearch`;
export const getGetPostCodesByStartsWith = `${zipCodeFeature}-get-GetPostCodesByStartsWith`;
export const getGetPostCodesByStartsWithAndEndWith = `${zipCodeFeature}-get-GetPostCodesByStartsWithAndEndWith`;
export const getGetPostCodeValid = `${zipCodeFeature}-get-GetPostCodeValid`;
export const getGetPostCodesByRadius = `${zipCodeFeature}-get-GetPostCodesByRadius`;
//waitingListLead
const waitingListLeadFeature = "waitingListLead";
export const getGetWaitingListLeadBasicInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadBasicInfo`;
export const getGetWaitingListLeadCriteriaInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadCriteriaInfo`;
export const getGetWaitingListLeadDetails = `${waitingListLeadFeature}-get-GetWaitingListLeadCriteriaInfo`;
export const patchAcquireWaitingListLead = `${waitingListLeadFeature}-patch-AcquireWaitingListLead`;
export const patchRejectWaitingListLead = `${waitingListLeadFeature}-patch-RejectWaitingListLead`;
export const getGetWaitingListLeadReceiptsInfo = `${waitingListLeadFeature}-get-GetWaitingListLeadReceiptsInfo`;
export const postSendWaitingListBookingLeadReceiptInfo = `${waitingListLeadFeature}-post-SendWaitingBookingListLeadReceiptInfo`;
export const postSendWaitingListBookingZipCodeInfo = `${waitingListLeadFeature}-post-SendWaitingBookingListZipCodeInfo`;

//PreLaunchRegistration
const preLaunchRegistrationFeature = "preLaunchRegistration";
export const getLeadReceiptsInfo = `${preLaunchRegistrationFeature}-get-getLeadReceiptsInfo`;

//Auth
const accountFeature = "account";
export const generateAccessTokenByMagicToken = `${accountFeature}-get-GenerateAccessTokenByMagicLink`;
export const generateAccessTokenByRefreshToken = `${accountFeature}-post-generateAccessTokenByRefreshToken`;

//Leads
const lead = "lead";
export const GetAllLeads = `${lead}-get-GetAllLeads`;
export const GetAllLeadProducts = `${lead}-get-GetAllLeadProducts`;

//Supplier
const supplier = "supplier";
export const GetSupplierInfoBySecuredId = `${supplier}-get-GetSupplierInfoBySecuredId`;
export const GetAuthenticateSupplierInfo = `${supplier}-get-GetAuthenticateSupplierInfo`;

//DeutscheSeniorenPortalLead
const deutscheSeniorenPortalLead = "deutscheSeniorenPortalLead";
export const GetDeutscheSeniorenPortalLeadDetails = `${deutscheSeniorenPortalLead}-get-GetGetDspLeadByBySecuredId`;

//WohnenImAlterLead
const wohnenImAlterLead = "wohnenImAlterLead";
export const GetWohnenImAlterLeadDetails = `${wohnenImAlterLead}-get-GetWohnenImAlterLeadBySecuredId`;
