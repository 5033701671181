import {
    FormControl,
    FormControlLabel,
    List,
    ListItem,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import { IAreaInformation, ZipCodeAreaType } from "../types";
import ZipCodeModalActions from "./ZipCodeModalActions";
import { generateUUIDFromTimestamp } from "utils";
import { useEffect, useState } from "react";
import {
    useGetZipCodesByGeoItemId,
    useGetSearchInfoByAreaTypeAndName
} from "../services/zipCodeService";
import { IZipCodeByAreaState } from "../types/zipCodeByAreaName/interfaces";
import ZipCodeSearchResultDetails from "./ZipCodeSearchResultDetails";
import { highlightMatch } from "../utils/zipCodeByAreaName";
import {
    getAreaTypeNameInGermanFromAreaType,
    getUrlTypeFromAreaType
} from "../utils/zipCodeByAreaName/mapper";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";
import { IGeoItemSearch } from "../services/dtos";
import { GermanCountryIso2 } from "../../../constants";

interface Props {
    handleZipCodeGroupAdd: (areaInformation: IAreaInformation) => void;
    handleModalCloseEvent: () => void;
}

/**
 * ZipCodeByAreaName is a React component that allows users to search for a specific area by name
 * and select an area from the search results. The callback function handleZipCodeGroupAdd
 * is called whenever the user adds an to the group to update the list of postal codes and potential number.
 *
 */
function ZipCodeByAreaName({
    handleZipCodeGroupAdd,
    handleModalCloseEvent
}: Readonly<Props>) {
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [zipCodeAreaState, setZipCodeAreaState] =
        useState<IZipCodeByAreaState>({
            isPostalCodeSelected: false,
            selectedGeoItem: undefined
        });
    const [zipCodeSelectedAreaType, setZipCodeSelectedAreaType] =
        useState<ZipCodeAreaType>(ZipCodeAreaType.State);

    useEffect(() => {
        const handler = setTimeout(() => {
            if (search.length >= 3) {
                setDebouncedSearch(search);
            }
        }, 200);

        return () => {
            clearTimeout(handler);
        };
    }, [search]);

    const { data: searchInfo, isFetching: isFetchingSearchInfo } =
        useGetSearchInfoByAreaTypeAndName(
            getUrlTypeFromAreaType(zipCodeSelectedAreaType),
            debouncedSearch,
            GermanCountryIso2,
            {
                enabled:
                    debouncedSearch.length >= 3 &&
                    !zipCodeAreaState.isPostalCodeSelected
            }
        );

    const { data: postalCodes, isFetching: isFetchingPostalCodes } =
        useGetZipCodesByGeoItemId(zipCodeAreaState?.selectedGeoItem?.id ?? "", {
            enabled: zipCodeAreaState?.selectedGeoItem !== undefined
        });

    function geoItemClick(geoItem: IGeoItemSearch) {
        setZipCodeAreaState({
            isPostalCodeSelected: true,
            selectedGeoItem: geoItem
        });
        setSearch(geoItem.name);
    }

    function zipCodeAreaTypeOnChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setDebouncedSearch("");
        setZipCodeSelectedAreaType(event.target.value as ZipCodeAreaType);
    }

    function zipCodeGroupAddClick() {
        if (
            zipCodeAreaState.selectedGeoItem === undefined ||
            postalCodes === undefined ||
            postalCodes.length === 0
        )
            return;
        handleZipCodeGroupAdd({
            areaId: generateUUIDFromTimestamp(),
            name: `${
                zipCodeAreaState.selectedGeoItem.name
            } | ${getAreaTypeNameInGermanFromAreaType(
                zipCodeAreaState.selectedGeoItem
                    .discriminator as ZipCodeAreaType
            )}`,
            totalNumberOfZipCode: postalCodes.length,
            zipCodeList: postalCodes.map((item) => item.zipCode)
        });
    }

    function searchTextFieldOnChange(
        event: React.ChangeEvent<HTMLInputElement>
    ) {
        setZipCodeAreaState((prev) => {
            return {
                ...prev,
                isPostalCodeSelected: false
            };
        });
        setDebouncedSearch("");
        setSearch(event.target.value);
    }

    function getSelectedClassName(
        zipCodeFormViewType: ZipCodeAreaType
    ): string {
        return zipCodeFormViewType === zipCodeSelectedAreaType
            ? "selected"
            : "";
    }

    function renderSearchInfo() {
        if (isFetchingSearchInfo) {
            return <div className="icon-loading">Loading</div>;
        } else if (searchInfo?.length === 0) {
            return <div className="icon-not-found">Kein Ergebnis</div>;
        } else {
            return (
                <List className="search-dropdown">
                    {searchInfo?.map((item) => {
                        const [before, match, after] = highlightMatch(
                            item.name,
                            search
                        );
                        return (
                            <ListItem
                                className="search-dropdown-item"
                                key={item.id}
                                onClick={() => geoItemClick(item)}
                            >
                                <List sx={{ padding: 0 }}>
                                    <ListItem
                                        sx={{
                                            padding: 0,
                                            display: "list-item"
                                        }}
                                    >
                                        {before}
                                        <strong>{match}</strong>
                                        {after}
                                    </ListItem>
                                    <ListItem
                                        sx={{
                                            padding: 0,
                                            display: "list-item"
                                        }}
                                    >
                                        <strong>
                                            {item.parentName},{" "}
                                            {item.countryIso2} -{" "}
                                            {getAreaTypeNameInGermanFromAreaType(
                                                item.discriminator
                                            )}
                                        </strong>
                                    </ListItem>
                                </List>
                            </ListItem>
                        );
                    })}
                </List>
            );
        }
    }

    return (
        <div>
            <div className="highlight-blue">Details hinterlegen:</div>
            <RadioGroup
                row
                aria-label="group"
                value={zipCodeSelectedAreaType}
                onChange={zipCodeAreaTypeOnChange}
                className="zipcode-radio-group-conainer"
            >
                <FormControlLabel
                    value={ZipCodeAreaType.State}
                    control={<Radio />}
                    label="Bundesland"
                    className={
                        "zipcode-radio-conainer " +
                        getSelectedClassName(ZipCodeAreaType.State)
                    }
                />
                <FormControlLabel
                    value={ZipCodeAreaType.City}
                    control={<Radio />}
                    label="Stadt"
                    className={
                        "zipcode-radio-conainer " +
                        getSelectedClassName(ZipCodeAreaType.City)
                    }
                />
                <FormControlLabel
                    value={ZipCodeAreaType.District}
                    control={<Radio />}
                    label="Stadtteil"
                    className={
                        "zipcode-radio-conainer " +
                        getSelectedClassName(ZipCodeAreaType.District)
                    }
                />
            </RadioGroup>
            <FormControl fullWidth sx={{ mt: 2 }} className="mb-20">
                <TextField
                    label="Gebiet hier eingeben"
                    variant="outlined"
                    value={search}
                    onChange={searchTextFieldOnChange}
                />
            </FormControl>
            {!zipCodeAreaState.isPostalCodeSelected && renderSearchInfo()}
            {zipCodeAreaState.isPostalCodeSelected && (
                <>
                    {isFetchingPostalCodes ||
                    zipCodeAreaState.selectedGeoItem === undefined ? null : (
                        <ZipCodeSearchResultDetails
                            title={`${zipCodeAreaState.selectedGeoItem.name} 
                                    - ${zipCodeAreaState.selectedGeoItem.parentName}, 
                                    ${zipCodeAreaState.selectedGeoItem.countryIso2}`}
                            type={getAreaTypeNameInGermanFromAreaType(
                                zipCodeAreaState.selectedGeoItem
                                    .discriminator as ZipCodeAreaType
                            )}
                            zipCodeList={
                                postalCodes !== undefined
                                    ? postalCodes.map((item) => item.zipCode)
                                    : []
                            }
                        ></ZipCodeSearchResultDetails>
                    )}
                </>
            )}
            <ZipCodeModalActions
                disabled={
                    !(
                        postalCodes?.length &&
                        zipCodeAreaState.isPostalCodeSelected
                    )
                }
                zipCodeGroupAddClick={zipCodeGroupAddClick}
                modalCloseButtonClick={handleModalCloseEvent}
            />
        </div>
    );
}

export default ZipCodeByAreaName;
