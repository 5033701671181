import { Box, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { IAreaInformation } from "../types";
import ZipCodeModalActions from "./ZipCodeModalActions";
import { generateUUIDFromTimestamp } from "utils";
import { useState } from "react";
import "../../../assets/styles/zipCode/ZipCodeModalContainer.scss";

interface Props {
    handleZipCodeGroupAdd: (areaInformation: IAreaInformation) => void;
    handleModalCloseEvent: () => void;
}

interface FormData {
    name: string;
    zipCodes: string;
}

/**
 * ZipCodeCustom is a React component that allows the user to search for a group of
 * custom zip codes. The callback function handleZipCodeGroupAdd
 * is called whenever the user adds an to the group to update the list of postal codes and potential number.
 */
function ZipCodeCustom({
    handleZipCodeGroupAdd,
    handleModalCloseEvent
}: Readonly<Props>) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormData>();
    const [zipCodeCount, setZipCodeCount] = useState(0);

    function getZipcodesFromCommaSeperatedString(zipCodesString: string): {
        count: number;
        data: string[];
    } {
        const zipCodes = zipCodesString.split(/[,\n]/).map((zip) => zip.trim());
        return {
            count: zipCodes.length,
            data: zipCodes
        };
    }

    function onZipCodesInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        const val = event.target.value;
        setZipCodeCount(
            val.trim() === ""
                ? 0
                : getZipcodesFromCommaSeperatedString(val).count
        );
    }

    function areValidZipCodes(zipCodesString: string) {
        const zipCodeRegex = /^\d{5}$/;
        const zipCodes =
            getZipcodesFromCommaSeperatedString(zipCodesString).data;
        return zipCodes.every((zipCode) => zipCodeRegex.test(zipCode));
    }

    function onSubmit(formData: FormData) {
        const { count, data } = getZipcodesFromCommaSeperatedString(
            formData.zipCodes
        );
        handleZipCodeGroupAdd({
            areaId: generateUUIDFromTimestamp(),
            name: formData.name,
            totalNumberOfZipCode: count,
            zipCodeList: data
        });
    }

    return (
        <div>
            <div className="highlight-blue">Details hinterlegen:</div>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                className="flex"
            >
                <div>
                    <p className="zipcode-input-heading">Name Gruppe</p>
                    <TextField
                        className="mb-20 zipcode-input"
                        label="Name angeben (max. 200 Zeichen)"
                        variant="outlined"
                        error={Boolean(errors.name)}
                        {...register("name", {
                            required: "Dieses Feld ist erforderlich"
                        })}
                    />
                    {errors.name && (
                        <div className="error-message">
                            {errors.name.message}
                        </div>
                    )}
                </div>
                <div>
                    <p className="zipcode-input-heading">
                        {`Postleizahlen (${zipCodeCount})`}
                    </p>
                    <TextField
                        className="w-100"
                        label="Postleizahl angeben (mit Komma oder mit einer neuen Zeile separieren)"
                        multiline
                        error={Boolean(errors.zipCodes)}
                        {...register("zipCodes", {
                            required: "Dieses Feld ist erforderlich",
                            onChange: (event) => {
                                onZipCodesInputChange(event);
                            },
                            validate: {
                                validZipCode: (value) =>
                                    areValidZipCodes(value) ||
                                    "Ungültige Postleitzahl(en)"
                            }
                        })}
                        rows={4}
                    />
                    {errors.zipCodes && (
                        <div className="error-message">
                            {errors.zipCodes.message}
                        </div>
                    )}
                </div>
            </Box>
            <ZipCodeModalActions
                disabled={false}
                zipCodeGroupAddClick={handleSubmit(onSubmit)}
                modalCloseButtonClick={handleModalCloseEvent}
            />
        </div>
    );
}

export default ZipCodeCustom;
