export enum LeadType {
    /// <summary>
    /// The lead is sourced from CRM with a type of capacity lead.
    /// </summary>
    Capacity = 0,

    /// <summary>
    /// The lead is sourced from CRM with a type of waiting list lead.
    /// </summary>
    WaitingList = 1,

    /// <summary>
    /// The lead is sourced from the EntlassManager system.
    /// </summary>
    EntlassManager = 2,

    /// <summary>
    /// The lead is sourced from the DeutschesSeniorenportal system.
    /// </summary>
    DeutschesSeniorenportal = 3,

    /// <summary>
    /// The lead is sourced from the WohnenImAlter system.
    /// </summary>
    WohnenImAlter = 4
}

export function getLeadTypeLabel(leadType: LeadType) {
    switch (leadType) {
        case LeadType.EntlassManager:
            return "Entlass-Manager";
        case LeadType.WaitingList:
            return "Verbund Pflegehilfe";
        case LeadType.DeutschesSeniorenportal:
            return "Deutsches Seniorenportal";
        case LeadType.WohnenImAlter:
            return "Wohnen im Alter";
        default:
            return "Unknown";
    }
}

export function getAllLeadType(): LeadType[] {
    return [
        LeadType.DeutschesSeniorenportal,
        LeadType.WohnenImAlter,
        LeadType.EntlassManager,
        LeadType.WaitingList
    ];
}
