import { useQuery } from "@tanstack/react-query";
import { get } from "../../../lib";
import { ISupplierInfo, ISupplierInfoDetails } from "./dtos";
import { GetAuthenticateSupplierInfo, getGetSupplierInfo, GetSupplierInfoBySecuredId } from "../../../constants/reactQueryKey";
import { AnfragenManagerClient } from "lib/httpService";

export const useGetSupplierInfo = (supplierId: string) => {
    return useQuery([getGetSupplierInfo, supplierId], async () => {
        return await get<ISupplierInfoDetails>(
            `/bookings/${supplierId}/supplierInfo`
        );
    });
};

export const useGetSupplierInfoBySecuredId = (
    securedId: string,
    options?: { enabled : boolean } // Make `enabled` optional
) => {
    return useQuery(
        [GetSupplierInfoBySecuredId, securedId],
        async () => {
            return await get<ISupplierInfo>(
                `/supplier/GetSupplier?secureId=` + securedId,
                AnfragenManagerClient
            );
        },
        {
            enabled: options?.enabled ?? true, 
        }
    );
};

export const useGetAuthenticateSupplierInfo = (
    options?: { enabled : boolean } // Make `enabled` optional
) => {
    return useQuery(
        [GetAuthenticateSupplierInfo],
        async () => {
            return await get<ISupplierInfo>(
                `/supplier/GetAuthenticateSupplierInfo`,
                AnfragenManagerClient
            );
        },
        {
            enabled: options?.enabled ?? true, 
        }
    );
};
