import GenericInformationTable, { TableRow } from "lib/table/GenericInformationTable";

interface SeniorInformationInfoProps {
    firstName: string | undefined;
    lastName: string | undefined;
    dateOfBirth: string | undefined;
    typeOfCare: string | undefined;
    careLevel: string | undefined;
}

/**
 * DeutscheSeniorenPortalLeadSeniorInformationTable renders key lead information using the GenericInformationTable component.
 */
export function DeutscheSeniorenPortalLeadSeniorInformationTable({
    firstName,
    lastName,
    dateOfBirth,
    typeOfCare,
    careLevel
}: Readonly<SeniorInformationInfoProps>) {
    const rows: TableRow[] = [
        { fieldName: "Name, Vorname:", value: firstName && lastName ? `${lastName}, ${firstName}` : firstName || lastName },
        { fieldName: "Geburtsdatum:", value: dateOfBirth }, 
        { fieldName: "Pflegeart:", value: typeOfCare }, 
        { fieldName: "Pflegegrad:", value: careLevel }
    ];

    return <GenericInformationTable title="Angaben der zu pflegenden Person" rows={rows} />;
}