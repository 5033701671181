export const isValidUUID = (id: string): boolean =>
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        id
    );

export function generateUUIDFromTimestamp(): string {
    const timestamp = Date.now();
    const uuidPattern = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
    const hexTimestamp = timestamp.toString(16).padStart(16, "0");

    return uuidPattern
        .replace(/[xy]/g, (c) => {
            const r = (Math.random() * 16) | 0;
            const v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        })
        .replace(/y/, hexTimestamp[12]);
}
export { isValidPhoneNumber } from "./validator";
export { getDayNameInGerman } from "./mapper";
