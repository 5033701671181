import { GetWohnenImAlterLeadDetails } from "constants/reactQueryKey";
import { IWohnenImAlterLeadDetails } from "./dtos";
import { AnfragenManagerClient, get } from "lib/httpService";
import { useQuery } from "@tanstack/react-query";

/**
 * @function useGetWohnenImAlterLeadDetails
 * @description Custom hook to fetch Wohnen Im Alter lead details using a secure ID.
 * @param {string} secureId - The secure ID of the lead.
 * @param {object} options - Optional configuration for the query.
 * @returns {object} - The result of the query.
 */
export const useGetWohnenImAlterLeadDetails = (
    secureId: string,
    options = {}
) => {
    return useQuery(
        [GetWohnenImAlterLeadDetails, secureId],
        async () => {
            return await get<IWohnenImAlterLeadDetails>(
                `/lead/GetWohnenImAlterById/${secureId}`,
                AnfragenManagerClient
            );
        },
        {
            ...options
        }
    );
};
