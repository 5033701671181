import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import BackToTopButton from "components/ui/BackToTopButton";
import { useGetWohnenImAlterLeadDetails } from "../services/WohnenImAlterLeadService";
import { getProductDisplayName, Product } from "types/product";
import ApiError from "lib/ApiError";
import WohnenImAlterLeadAcquired from "./WohnenImAlterLeadAcquired";
import { WohnenImAlterLeadClientInformationTable } from "./WohnenImAlterLeadClientInformationTable";
import { WohnenImAlterLeadProductCriteriaTable } from "./WohnenImAlterLeadProductCriteriaTable";

const WohnenImAlterLeadOverview = () => {
    const { secureId } = useParams() as { secureId: string };
    const navigate = useNavigate();
    const {
        data: detailsInfo,
        isSuccess,
        isError,
        error
    } = useGetWohnenImAlterLeadDetails(secureId);

    useEffect(() => {
        // useEffect hook to perform side effects. In this case, it checks if an API call resulted in an error.
        // If there is an error, specifically a 404 error, it navigates the user to a 404 page.
        // The dependency array includes isSuccess and isError to only rerun this effect when these values change.
        if (error) {
            if (error instanceof ApiError && error.status === 404) {
                navigate("/404", { replace: true });
            }
        }
    }, [error, isSuccess]);

    return (
        <div className="waiting-list-lead-container">
            <BackToTopButton />
            <h1 className="text-blue">Anfrage: {detailsInfo?.leadNumber}</h1>
            <WohnenImAlterLeadAcquired />
            {detailsInfo != null && (
                <WohnenImAlterLeadClientInformationTable
                    firstName={detailsInfo.clientInformation.firstName}
                    lastName={detailsInfo.clientInformation?.lastName}
                    email={detailsInfo.clientInformation?.email}
                    accessibility={detailsInfo.clientInformation.accessibility}
                    message={detailsInfo.message}
                    phoneNumber={detailsInfo.clientInformation.phoneNumber}
                    productName={getProductDisplayName(detailsInfo.productId)}
                    productId={detailsInfo.productId}
                />
            )}

            {detailsInfo != null &&
                [Product.InPatientCare, Product.JobApplication].includes(
                    detailsInfo.productId
                ) && (
                    <WohnenImAlterLeadProductCriteriaTable
                        careLevel={detailsInfo.productCriteria.Pflegegrad}
                        moveInDate={detailsInfo.productCriteria.Einzugstermin}
                        contactReason={detailsInfo.productCriteria.Kontaktgrund}
                        department={detailsInfo.productCriteria.Fachbereich}
                    />
                )}
        </div>
    );
};

export default WohnenImAlterLeadOverview;
