// Import Statements
import axios, { AxiosInstance } from "axios";
import {
    ANFRAGEN_MANAGER_BACKEND_URL,
    BACKENDURL,
    PflegeHilfeAuthInformation
} from "../constants/pflgehilfeData";
import ApiError from "./ApiError";
import { clearTokenCookies, generateBearerToken, getHeaderWithBearerToken, loginWithRefreshToken, UnAuthorizedErrorMessage } from "./auth/authService";


// Create API Client
export const createApiClient = (baseUrl: string, auth?: any, header?: any): AxiosInstance => {
    return axios.create({
        baseURL: baseUrl,
        auth: auth,
        headers : header
    });
};

// Error Checking
const errorCheck = (error: any): ApiError => {
    if (axios.isAxiosError(error)) {
        let errorMessage = error.message;
        const response = error.response;
        if (axios.isCancel(error)) {
            return new ApiError("Request cancelled by user", 0);
        } else if (response) {
            return new ApiError(errorMessage, response.status, response.data);
        }
    }
    return error;
};

// ========== Core API Methods ==========

// Generic Request Function
const request = async <T>(
    method: "get" | "post" | "put" | "delete" | "patch",
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => {
    try {
        const response = await apiClient.request<T>({
            method,
            url: relativePath,
            data
        });
        const responseData: T = response.data;
        return responseData;
    } catch (error:any) {
        throw errorCheck(error);
    }
};

// GET Request
export const get = <T>(
    relativePath: string,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("get", relativePath, undefined, apiClient);

// POST Request
export const post = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => {
    return request<T>("post", relativePath, data, apiClient);
};

// PUT Request
export const put = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("put", relativePath, data, apiClient);

export const patch = <T>(
    relativePath: string,
    data?: any,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("patch", relativePath, data, apiClient);

// DELETE Request
export const del = <T>(
    relativePath: string,
    apiClient: AxiosInstance = PflgeApiClient
): Promise<T> => request("delete", relativePath, undefined, apiClient);

// ========== CRM API Client Creation ==========
const PflgeApiClient = createApiClient(BACKENDURL, PflegeHilfeAuthInformation);


// ========== AnfragenManager API Client Creation ==========
export const AnfragenManagerClient = createApiClient(ANFRAGEN_MANAGER_BACKEND_URL,undefined, getHeaderWithBearerToken());

// Add a response interceptor for AnfragenManagerClient
AnfragenManagerClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Prevent infinite loop
            try {
                const newAccessTokenDetails = await loginWithRefreshToken();
                if (newAccessTokenDetails !== UnAuthorizedErrorMessage) {
                    originalRequest.headers.Authorization = generateBearerToken(
                        newAccessTokenDetails.accessToken
                    );
                    return AnfragenManagerClient(originalRequest);
                } else {
                    clearTokenCookies();
                    return Promise.reject("Unauthorized access");
                }
            } catch (refreshError) {
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);
