import GenericInformationTable, {
    TableRow
} from "lib/table/GenericInformationTable";
import { Product } from "types/product";

interface ClientInformationInfoProps {
    firstName: string;
    lastName: string;
    productName: string;
    email: string;
    accessibility: string | null;
    message: string | null;
    phoneNumber: string;
    productId: Product;
}

/**
 * WohnenImAlterLeadClientInformationTable renders key lead information using the GenericInformationTable component.
 */
export function WohnenImAlterLeadClientInformationTable({
    firstName,
    lastName,
    productName,
    email,
    message,
    phoneNumber,
    accessibility
}: Readonly<ClientInformationInfoProps>) {
    /**
     * Process the accessibility value to return a more readable string.
     * @param value - The accessibility value to process
     * @returns The processed accessibility value
     */
    function processAccessibility(
        value: string | null | undefined
    ): string | undefined {
        if (value === null || value === undefined) {
            return undefined; // Return null if the value is null or undefined
        }
        if (value.trim() === "") {
            return "keine Angabe"; // Return "keine Angabe" if the value is empty
        }
        // Return a custom mapping or the value itself for others
        const timeMapping: Record<string, string> = {
            "9_12": "9-12 Uhr",
            "12_15": "12-15 Uhr",
            "15_19": "15-19 Uhr",
            "19_22": "19-22 Uhr"
        };

        return timeMapping[value] || value;
    }

    const rows: TableRow[] = [
        {
            fieldName: "Name, Vorname:",
            value:
                firstName && lastName
                    ? `${lastName}, ${firstName}`
                    : firstName || lastName
        },
        { fieldName: "Telefonnummer:", value: phoneNumber },
        { fieldName: "Bedarf:", value: productName },
        { fieldName: "Email:", value: email },
        ...(message
            ? [{ fieldName: "Nachricht:", value: message, isExpandable: true }]
            : []),
        ...(processAccessibility(accessibility)
            ? [
                  {
                      fieldName: "Erreichbarkeit:",
                      value: processAccessibility(accessibility)
                  }
              ]
            : [])
    ];

    return (
        <GenericInformationTable
            title="Die wichtigsten Daten auf einen Blick"
            rows={rows}
        />
    );
}
