import React, { useEffect, useState } from "react";
import { useIsFetching, useIsMutating } from "@tanstack/react-query";
import "../../assets/styles/components/LoadingSpinner.scss";
import {
    GetAllLeads,
    getGetAreasBySearch,
    getGetPostCodeValid
} from "../../constants/reactQueryKey";

function LoadingSpinner() {
    const notLoadingSpinnerUrls = [
        getGetAreasBySearch,
        getGetPostCodeValid,
        GetAllLeads
    ];

    const [delayedIsLoading, setDelayedIsLoading] = useState(false); // State for spinner visibility
    const isFetching = useIsFetching({
        predicate: (query) =>
            typeof query.queryKey[0] === "string" &&
            !notLoadingSpinnerUrls.includes(query.queryKey[0])
    });

    const isMutating = useIsMutating();

    const isLoading = isFetching > 0 || isMutating > 0;

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null; // Initialize timer with null

        if (isLoading) {
            // Delay showing the spinner by 1500ms
            timer = setTimeout(() => {
                setDelayedIsLoading(true);
            }, 500);
        } else {
            // Hide the spinner and clear timeout
            if (timer) {
                clearTimeout(timer);
            }
            setDelayedIsLoading(false);
        }

        return () => {
            if (timer) {
                clearTimeout(timer); // Cleanup timeout on unmount or state change
            }
        };
    }, [isLoading]);

    return (
        <div
            className={`loading-spinner ${
                delayedIsLoading ? "loading-spinner-show" : ""
            }`}
        >
            <div className="icons-wait loading-spinner-icon"></div>
        </div>
    );
}

export default LoadingSpinner;
