import emailjs from "emailjs-com";
import { IPreLunchRegistrationFormData } from "../components/PreLunchRegistrationForm";

interface IEmailConfig {
    public_key: string;
    template_id: string;
    service_id: string;
    send_to: string;
}

export const sendEmails = async (
    formData: IPreLunchRegistrationFormData,
    onSuccess: () => void,
    onError: () => void
) => {
    const config: IEmailConfig[] = [
        {
            public_key: "ij-he9Vrfh7jpRUwy",
            template_id: "template_2fhk82r",
            service_id: "service_w0mzl2c",
            send_to: "anfragen-manager-it@pflegehilfe.de"
        }
    ];

    for (const element of config) {
        const result = {
            public_key: element.public_key,
            template_id: element.template_id,
            service_id: element.service_id,
            template: {
                name: formData.name,
                company: formData.companyName,
                email_address: formData.emailAddress,
                phone_number: formData.mobileNumber,
                send_to: element.send_to
            }
        };

        try {
             emailjs.init(result.public_key);
             await emailjs.send(
                 result.service_id,
                 result.template_id,
                 result.template
             );
            onSuccess();
            break;
        } catch (error: any) {
            onError();
            throw new Error(
                `Error with public_key ${element.public_key}: ${error.message}`
            );
        }
    }
};
