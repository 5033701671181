import { createContext, useContext } from "react";

// Create the context for supplierId
export const SupplierIdContext = createContext<string | undefined>(undefined);

// Create a custom hook to access the context value
export const useSupplierId = () => {
    const context = useContext(SupplierIdContext);
    if (context === undefined) {
        throw new Error("useSupplierId must be used within a SupplierContext.Provider");
    }
    return context;
};
