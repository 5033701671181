import {
    QueryClient,
    UseMutationOptions,
    useMutation,
    useQuery
} from "@tanstack/react-query";
import { get, patch } from "../../../lib";
import {
    IWaitingListDetailsInfo,
} from "./dtos";
import {
    getGetWaitingListLeadDetails,
    patchAcquireWaitingListLead,
    patchRejectWaitingListLead
} from "../../../constants/reactQueryKey";
import ApiError from "../../../lib/ApiError";
import { AnfragenManagerClient } from "lib/httpService";

export const useGetWaitingListLeadDetails = (
    secureId: string,
    options = {}
) => {
    return useQuery(
        [getGetWaitingListLeadDetails, secureId],
        async () => {
            return await get<IWaitingListDetailsInfo>(
                `/lead/GetWaitingListLeadById/${secureId}`,AnfragenManagerClient
            );
        },
        {
            ...options
        }
    );
};

export const usePatchMakeWaitingListLeadAcquired = (
    secureId: string,
    options: UseMutationOptions<void, ApiError, void, unknown> = {}
) => {
    return useMutation<void, ApiError, void>({
        mutationKey: [patchAcquireWaitingListLead, secureId],
        mutationFn: () => patch<void>(`/lead/AcquireWaitingListLead/${secureId}`,undefined,AnfragenManagerClient),
        ...options
    });
};


export const usePatchMakeWaitingListLeadRejected = (
    secureId: string,
    options: UseMutationOptions<void, ApiError, void, unknown> = {}
) => {
    return useMutation<void, ApiError, void>({
        mutationKey: [patchRejectWaitingListLead, secureId],
        mutationFn: () => patch<void>(`/lead/RejectWaitingListLead/${secureId}`,undefined,AnfragenManagerClient),
        ...options
    });
};

export const invalidateWaitingListLeadQueries = async (
    queryClient: QueryClient
) => {
    queryClient.invalidateQueries([getGetWaitingListLeadDetails]);
};

