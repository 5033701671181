import { useNavigate, useParams } from "react-router-dom";
import { useGetWaitingListLeadDetails } from "../services/waitingListLeadService";
import "../../../assets/styles/waitingListLead/WaitingListLeadOverview.scss";
import WaitingListLeadStateClosed from "./WaitingListLeadStateClosedV1";
import { WaitingListLeadState } from "../types/enums";
import { getCommaSeparatedPhoneNumberFromArray } from "../utils/waitingListLeadOverview";
import ApiError from "../../../lib/ApiError";
import { useContext, useEffect, useState } from "react";
import BackToTopButton from "../../../components/ui/BackToTopButton";
import {PreLaunchRegistrationPromotion } from "../../PreLaunchRegistration";
import WaitingListLeadStateAcquiredV1 from "./WaitingListLeadStateAcquiredV1";
import WaitingListLeadBasicInformationTableV1 from "./WaitingListLeadBasicInformationTableV1";
import WaitingListLeadProductCriteriaInformationTableV1 from "./WaitingListLeadProductCriteriaInformationTableV1";
import WaitingListLeadStateOpenV1 from "./WaitingListLeadStateOpenV1";
import WaitingListLeadStateRejectedV1 from "./WaitingListLeadStateRejectedV1";
import WaitingListLeadStateAcquiredWithClosedDateV1 from "./WaitingListLeadStateAcquiredWithClosedDateV1";
import { SupplierAuthenticateContext } from "features/supplierInfo/context/SupplierAuthenticateContext";


/**
 *
 * WaitingListLeadOverview is a React component that serves as the main interface for displaying detailed information about a specific waiting list lead.
 * It fetches the lead's basic information based on a secure ID obtained from the URL parameters and manages API errors,
 * such as redirecting to a 404 page if the lead is not found.
 *
 */
function WaitingListLeadOverviewV1() {
    const { secureId } = useParams() as { secureId: string };
    const isAuthenticated = useContext(SupplierAuthenticateContext);
    const navigate = useNavigate();
    const {
        data: waitingListLeadDetailsInfo,
        isSuccess : waitingListDetailsSuccess,
        isError : waitingListDetailsError,
        error : waitingListDetailsOnError
    } = useGetWaitingListLeadDetails (secureId); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        // useEffect hook to perform side effects. In this case, it checks if an API call resulted in an error.
        // If there is an error, specifically a 404 error, it navigates the user to a 404 page.
        // The dependency array includes isSuccess and isError to only rerun this effect when these values change.
        if (waitingListDetailsError) {
            if (waitingListDetailsOnError instanceof ApiError && waitingListDetailsOnError.status === 404) {
                navigate("/404", { replace: true });
            }
        }
        // Check if isRegisterForAM is in localStorage
        const isSet = localStorage.getItem('isRegisterForAM') !== null;
        setIsRegisterForAMSet(isSet);

    }, [waitingListDetailsError , waitingListDetailsSuccess]);
    
    const [isRegisterForAMSet, setIsRegisterForAMSet] = useState(false);
    const [preLaunchRegistrationPromotionModalOpen, setPreLaunchRegistrationPromotionModal] = useState<boolean>(false);

    // Defines a function to render different components based on the waiting list lead's state.
    // This function takes a state parameter and returns a JSX component according to the provided state.
    function getWaitingListLeadStatusView(
        state: WaitingListLeadState,
        isClosedByClient: boolean,
        isPersonalProduct:boolean
    ) {
        
        if (isClosedByClient) {
            if (state === WaitingListLeadState.Acquired) {
                return <WaitingListLeadStateAcquiredWithClosedDateV1 isPersonalProduct={isPersonalProduct}/>;
            }
            return <WaitingListLeadStateClosed isPersonalProduct={isPersonalProduct}/>;
        }
        switch (state) {
            case WaitingListLeadState.Open:
                return <WaitingListLeadStateOpenV1 
                    secureId={secureId} isPersonalProduct={isPersonalProduct} 
                    onSuccessCallback={() => setTimeout(() => showPreLaunchRegistrationPromotionModal(), 2000)}
                />;
            case WaitingListLeadState.Acquired:
                return <WaitingListLeadStateAcquiredV1 isPersonalProduct={isPersonalProduct}/>;
            default:
                return <WaitingListLeadStateRejectedV1/>;
        }
    }

    function showPreLaunchRegistrationPromotionModal(){
        if(!isRegisterForAMSet && !isAuthenticated){
             setPreLaunchRegistrationPromotionModal(true);
        }
    }

    function onSuccess(){
        setIsRegisterForAMSet(true);
        localStorage.setItem('isRegisterForAM', 'true');
    }

    function onError(){
        localStorage.setItem('isRegisterForAM', 'false');
        setIsRegisterForAMSet(false);
    }

    return (
        <div className="waiting-list-lead-container">

            <PreLaunchRegistrationPromotion               
                preLaunchRegistrationPromotionModalOpen = {preLaunchRegistrationPromotionModalOpen}
                setPreLaunchRegistrationPromotionModal ={setPreLaunchRegistrationPromotionModal}
                onEmailSend={() =>  onSuccess()}
                onEmailError={() =>  onError()}
              />

            <BackToTopButton />
            
            {
                (   waitingListLeadDetailsInfo?.waitingListLeadState == WaitingListLeadState.Rejected || 
                    waitingListLeadDetailsInfo?.waitingListLeadState == WaitingListLeadState.Acquired) && 
                    (
                        <div className="waiting-list-lead-nav-box">
                            {
                                (!isRegisterForAMSet && !isAuthenticated) && (
                                    <a className="link-pointer pull-right" onClick={() => showPreLaunchRegistrationPromotionModal()}>
                                        Weitere Anfragen erhalten
                                    </a>
                                )
                            }
                        </div>
                    )
            }

            <h1 className="text-blue">
                Anfrage: {waitingListLeadDetailsInfo?.waitingListLeadNumber}
            </h1>

            {waitingListLeadDetailsInfo &&
                getWaitingListLeadStatusView(
                    waitingListLeadDetailsInfo.waitingListLeadState,
                    waitingListLeadDetailsInfo.isClosedByClient,
                    waitingListLeadDetailsInfo.isPersonalProduct
                )}
            {waitingListLeadDetailsInfo && (
                <WaitingListLeadBasicInformationTableV1
                    firstName={waitingListLeadDetailsInfo.firstName}
                    lastName={waitingListLeadDetailsInfo.lastName}
                    productName={waitingListLeadDetailsInfo.productName}
                    clientAvailability={
                        waitingListLeadDetailsInfo.clientAvailability
                    }
                    waitingListLeadState={
                        waitingListLeadDetailsInfo.waitingListLeadState
                    }
                    requestRegion={waitingListLeadDetailsInfo.requestRegion}
                    requestZipCode={waitingListLeadDetailsInfo.requestZipCode}
                    phoneNumber={getCommaSeparatedPhoneNumberFromArray(
                        waitingListLeadDetailsInfo.phoneNumbers
                    )}
                    isClosedByClient={waitingListLeadDetailsInfo.isClosedByClient}
                    isPersonalProduct={waitingListLeadDetailsInfo.isPersonalProduct}
                />
            )}
            {waitingListLeadDetailsInfo && (
                <WaitingListLeadProductCriteriaInformationTableV1
                    waitingListLeadCriteriaInfo={{
                        waitingListLeadState: waitingListLeadDetailsInfo.waitingListLeadState,
                        isClosedByClient: waitingListLeadDetailsInfo.isClosedByClient,
                        isPersonalProduct: waitingListLeadDetailsInfo.isPersonalProduct,
                        productCriteriaList: waitingListLeadDetailsInfo.productCriteriaList,
                    }}
                />
            )}
        </div>
    );
}

export default WaitingListLeadOverviewV1;
