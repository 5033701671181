export function getMoveInDateDisplayName(value: string): string {
    if (value.trim() === "") {
        return "keine Angabe"; // Return "keine Angabe" if the value is empty
    }

    // Mapping of Einzugstermin to corresponding description
    const moveInDateMapping: Record<string, string> = {
        "1week": "Weniger als 1 Woche",
        "2weeks": "Weniger als 2 Wochen",
        "34weeks": "3 - 4 Wochen",
        "12months": "1 - 2 Monate",
        "34months": "3 - 4 Monate"
    };

    // Return the mapped value or the original value if not found
    return moveInDateMapping[value] || value;
}

export function getContactReasonDisplayName(value: string): string {
    // Mapping of Einzugstermin to corresponding description
    const contactReasonMapping: Record<string, string> = {
        availability: "Pflegeplatz",
        viewing: "Besichtigung",
        brochure: "Infomaterial"
    };

    // Return the mapped value or the original value if not found
    return contactReasonMapping[value] || value;
}

export function getDepartmentDisplayName(id: string): string {
    // Mapping of string numbers to names
    const departmentMapping: Record<string, string> = {
        "18": "Pflegefachkräfte",
        "19": "Pflegehilfskräfte",
        "20": "Wohnbereichsleitung",
        "21": "Heimleitung",
        "22": "Pflegedienstleitung",
        "23": "Alltagsbegleiter",
        "24": "Betreuungskräfte",
        "25": "Sonstige Pflegeberufe",
        "26": "Ergotherapie",
        "27": "Physiotherapie",
        "28": "Logopädie",
        "29": "Podologie",
        "30": "Kaufmännische Berufe",
        "31": "Hauswirtschaft",
        "32": "Küchenkräfte",
        "33": "Fahrdienst",
        "34": "Sonstige Jobs",
        "61": "Pflegeassistenz",
        "62": "Intensivpflegefachkraft",
        "63": "Praxisanleitung",
        "64": "Hygienefachkraft/-beauftragte/r",
        "65": "Atmungstherapie",
        "66": "Qualitätsmanagement",
        "67": "Case Management",
        "87": "Gesundheits- und Krankenpflegefachkraft",
        "88": "Gesundheits- und Kinderkrankenpflegefachkraft",
        "91": "Heilerziehungspfleger/in"
    };
    return departmentMapping[id] || "Unknown ID";
}
