import { getDataByCookie } from "../utils/cookie";

export const PflegeHilfeAuthInformation = {
    username: "AnfragenManagerClient",
    password: "Xu56*ut%bPN!P5Ce"
};

export const BACKENDURL = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL + "/api/1.0/anfragen-manager"
    : "";
    
export const ANFRAGEN_MANAGER_BACKEND_URL = process.env.REACT_APP_ANFRAGEN_MANAGER_BACKEND_URL
    ? process.env.REACT_APP_ANFRAGEN_MANAGER_BACKEND_URL + "/api/1/"
    : "";
