export enum StateStatus {
    Open = 0,
    Acquired = 1,
    Rejected = 2,
    Pending = 3,
    Closed = 4,
}

export function getLeadStatus(status: StateStatus): string {
    switch(status){
        case StateStatus.Open:
            return "Neu"
        case StateStatus.Acquired:
            return "Bestätigt"
        case StateStatus.Rejected:
            return "Abgelehnt"
        case StateStatus.Pending:
            return "Ausstehend"
        case StateStatus.Closed:
            return "Geschlossen"
        default:
            return "Unknown"
    }
}

export function getAllLeadStatus(){
    return[StateStatus.Open,StateStatus.Acquired,StateStatus.Rejected,StateStatus.Closed]
}

export function getLeadStatusClass(status: StateStatus): string {
    switch(status){
        case StateStatus.Open:
            return "text-italic info bolder"
        case StateStatus.Acquired:
            return "text-italic success bolder"
        case StateStatus.Rejected:
            return "text-italic warning bolder"
        case StateStatus.Pending:
            return "text-italic bolder"
        case StateStatus.Closed:
            return "text-italic error bolder"
        default:
            return "text-italic bolder"
    }
}