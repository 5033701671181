import { useNavigate, useParams, NavLink } from "react-router-dom";
import { useGetSupplierInfo } from "../services/supplierInfoService";
import {
    BodyContainer,
    Header,
    MobileNavigationDrawer,
    SideNavbar
} from "../../../components/ui";
import { useEffect } from "react";
import { isValidUUID } from "../../../utils";
import ApiError from "../../../lib/ApiError";
import { useNavigationDrawer } from "../../../context";
import { SupplierContext }  from "../context/SupplierContext";

/**
 * NonPartneredSupplierContainer is a React component that serves as a container for displaying
 * the interface for non-partnered suppliers(mainly service pages). It manages
 * the visibility of navigation elements based on screen size and the state of the navigation drawer.
 *
 */
function NonPartneredSupplierContainer() {
    const { supplierId } = useParams() as { supplierId: string };
    const { secureId } = useParams() as { secureId: string };
    const navigate = useNavigate();
    const { isNavigationDrawerOpen } = useNavigationDrawer();
    const {
        data: supplierInfo,
        isError,
        error,
        isSuccess
    } = useGetSupplierInfo(supplierId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        //if supplierId is not valid, navigate to 404 page
        if (!isValidUUID(supplierId)) {
            navigate("/404", { replace: true });
        }

        if (isError) {
            if (error instanceof ApiError) {
                if (error.status === 404) {
                    navigate("/404", { replace: true });
                }
            }
        }
    }, [isSuccess, isError]);

    function getClassBasedOnActivePath(isActive: boolean) {
        return `sidebar-link nav-item navigation-drawer-link text-blue ${
            isActive ? " active non-clickable" : ""
        }`;
    }

    const NavLinks = (
        <>
            <NavLink
                to={`/${supplierId}/waitinglistrequests/${secureId}`}
                className={({ isActive }) =>
                    getClassBasedOnActivePath(isActive)
                }
                end
            >
                Anfragen
            </NavLink>
            <NavLink
                to={`/${supplierId}/waitinglistrequests/${secureId}/contactform`}
                className={({ isActive }) =>
                    getClassBasedOnActivePath(isActive)
                }
            >
                Kontaktdaten
            </NavLink>
            <NavLink
                to={`/${supplierId}/waitinglistrequests/${secureId}/zipcodeform`}
                className={({ isActive }) =>
                    getClassBasedOnActivePath(isActive)
                }
            >
                Zielgebiet
            </NavLink>
        </>
    );

    return (
        <>
            <SupplierContext.Provider value={supplierInfo?.supplierName}>
                <Header isPartnered={false} showNavigationDrawerIcon={true} />
                <BodyContainer hideOutlet={isNavigationDrawerOpen}>
                    <SideNavbar
                        className="hidden-small-screen"
                        name={supplierInfo?.supplierName ?? ""}
                    >
                        {NavLinks}
                    </SideNavbar>
                    <MobileNavigationDrawer
                        hidden={!isNavigationDrawerOpen}
                        className="show-small-screen"
                    >
                        {NavLinks}
                    </MobileNavigationDrawer>
                </BodyContainer>
            </SupplierContext.Provider>
        </>
    );
}

export default NonPartneredSupplierContainer;
