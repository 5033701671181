import { Modal } from "@mui/material";
import { useState } from "react";
import "../../../assets/styles/preLaunchRegistrationPromotion/preLaunchRegistrationPromotion.scss";
import PreLunchRegistrationFormModal from "./PreLunchRegistrationFormModal";

interface Props {
    preLaunchRegistrationPromotionModalOpen: boolean;
    setPreLaunchRegistrationPromotionModal: (open: boolean) => void;
    onEmailSend(): void;
    onEmailError(): void;
}
/**
 * Pre-Registration promotion popup
 */
function PreLaunchRegistrationPromotion({
    preLaunchRegistrationPromotionModalOpen,
    setPreLaunchRegistrationPromotionModal,
    onEmailSend,
    onEmailError
}: Readonly<Props>) {
    const [confirmationModel, setConfirmationModel] = useState<boolean>(false);

    function click() {
        setConfirmationModel(true);
        setPreLaunchRegistrationPromotionModal(false);
    }

    return (
        <div>
            <Modal
                className="promotion-popup-container"
                open={preLaunchRegistrationPromotionModalOpen}
                aria-labelledby="acquired-popup"
                aria-describedby="acquired-popup-information"
            >
                <div className="promotion-popup-modal-body">
                    <div
                        className="icon icon-close"
                        onClick={() =>
                            setPreLaunchRegistrationPromotionModal(false)
                        }
                    ></div>
                    <h2 id="pre-lunch-registration-popup-title">
                        Ihre Lösung zur Gewinnung neuer Klienten
                    </h2>
                    <p className="mtb-30 mr-10-desktop">
                        Sobald Sie <strong>freie Kapazitäten</strong> haben,
                        erhalten <strong>Sie passende Klienten</strong> in Ihrem{" "}
                        <strong>persönlichen Anfragen-Manager. </strong>
                    </p>
                    <div className="promotion-popup-body">
                        <div>
                            <div className="lead-dummy mr-10-desktop"></div>
                        </div>

                        <div className="promotion-popup-message-box">
                            <h3>Ihre Vorteile</h3>
                            <ul className="list-check">
                                <li>
                                    Sparen Sie <strong>Zeit</strong> und{" "}
                                    <strong>Geld</strong> durch einen{" "}
                                    <strong>optimierten Akquiseprozess</strong>
                                </li>
                                <li>
                                    Erhalten Sie individuell auf Sie{" "}
                                    <strong>vorqualifizierte Anfragen</strong>
                                </li>
                                <li>
                                    Verwalten Sie{" "}
                                    <strong>private und Klinikanfragen</strong>{" "}
                                    an einem Ort verwalten
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="button-container">
                        <button className="btn mobile-button" onClick={click}>
                            Mehr über diese Lösung erfahren
                        </button>
                    </div>
                </div>
            </Modal>

            <PreLunchRegistrationFormModal
                popupOpen={confirmationModel}
                setPopupOpen={setConfirmationModel}
                onEmailSend={() => onEmailSend()}
                onEmailError={() => onEmailError()}
            ></PreLunchRegistrationFormModal>
        </div>
    );
}

export default PreLaunchRegistrationPromotion;
