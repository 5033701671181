import { Box, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import "../../../assets/styles/preLaunchRegistrationPromotion/preLaunchRegistrationPromotion.scss";
import { useSupplierName } from "../../supplierInfo/context/SupplierContext";
import { useAMPreRegistrationFormValidationRules } from "../hooks/useAMPreRegistrationFormValidationRules";

export interface IPreLunchRegistrationFormData {
    name: string;
    emailAddress: string;
    mobileNumber: string;
    companyName: string;
}

interface IPreLunchRegistrationFormProps {
    onSubmit: (
        PreLunchRegistrationFormData: IPreLunchRegistrationFormData
    ) => void;
    PreLunchRegistrationFormData?: IPreLunchRegistrationFormData;
}

/**
 * Pre-Registration form  for anfragen manager
 */

function PreLunchRegistrationForm({
    onSubmit,
    PreLunchRegistrationFormData,
}: Readonly<IPreLunchRegistrationFormProps>) {
    const { handleSubmit, control, reset } =
        useForm<IPreLunchRegistrationFormData>({
            mode: "onBlur"
        });

    const supplierName = useSupplierName();

    useEffect(() => {
        if (PreLunchRegistrationFormData) {
            reset({
                ...PreLunchRegistrationFormData,
                companyName: supplierName ?? ""
            });
        }
    }, [PreLunchRegistrationFormData, supplierName, reset]);

    const { emailAddressRules } = useAMPreRegistrationFormValidationRules({});

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="info-section">
                <div className="flex">
                    <div className="col-6 mr-15-mobile">
                        <label className="bolder">Name:</label>
                        <Box className="input">
                            <Controller
                                name="name"
                                control={control}
                                defaultValue={PreLunchRegistrationFormData?.name ?? ""} // Ensure no null values
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="empty-bg-change"
                                        placeholder="Name"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                    <div className="col-6">
                        <label className="bolder">Name Ihres Unternehmens:</label>
                        <Box className="input">
                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue={PreLunchRegistrationFormData?.companyName ?? ""} // Ensure no null values
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        className="empty-bg-change"
                                        placeholder="Name Ihres Unternehmens"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                </div>

                <div className="flex">
                    <div className="col-6 mr-15-mobile">
                        <label className="bolder">E-Mail-Adresse*:</label>
                        <Box className="input">
                            <Controller
                                name="emailAddress"
                                control={control}
                                defaultValue={PreLunchRegistrationFormData?.emailAddress ?? ""} // Ensure no null values
                                rules={emailAddressRules}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="E-Mail-Adresse"
                                        variant="outlined"
                                        className="empty-bg-change"
                                        fullWidth
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                        <span className="text-align-right">
                            <i className="text-align-right">*Pflichtfeld</i>
                        </span>
                    </div>

                    <div className="col-6">
                        <label className="bolder">Mobilnummer:</label>
                        <Box className="input">
                            <Controller
                                name="mobileNumber"
                                control={control}
                                defaultValue={PreLunchRegistrationFormData?.mobileNumber ?? ""} // Ensure no null values
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        placeholder="Mobilnummer"
                                        className="empty-bg-change"
                                        variant="outlined"
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            />
                        </Box>
                    </div>
                </div>
                <div className="button-container">
                    <button type="submit" className="mobile-button btn">
                        Speichern
                    </button>
                </div>
            </div>
        </form>
    );
}

export default PreLunchRegistrationForm;